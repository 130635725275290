import axios from "../plugins/axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../atoms/user";

interface AuthContainerProps {}

const withApiUser =
  <P extends object>(
    Component: React.ComponentType<P & AuthContainerProps>
  ): React.FC<P & AuthContainerProps> =>
  // eslint-disable-next-line
  (props: AuthContainerProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [user, setUser] = useRecoilState(userState);

    useEffect(() => {
      getUserData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getUserData = async () => {
      try {
        const { data } = await axios.get(`admin/users/me`);
        setUser(data);
        setIsLoading(false);
        return true;
      } catch (error) {
        setFailed(true);
        // throw new Error(error);
      }
      setIsLoading(false);
    };

    if (failed) return <div />;
    if (user === null) return <div />;

    return (
      <div>
        {isLoading ? (
          <div>Loading</div>
        ) : (
          // @ts-ignore
          <Component {...{ ...props }} />
        )}
      </div>
    );
  };

export default withApiUser;
