import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlotModel, ReportModel } from "../../../../types/api";
import { ReportSectionType } from "../../../../types/app";
import axios from "../../../../plugins/axios";
import { getFloorName, getSectionName } from "../../../../utils/stringUtil";

interface ScreenProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

const PlotProgressTab: React.FC<ScreenProps> = ({ plot, getPlot, siteId }) => {
  const [sections, setSections] = useState<ReportSectionType[]>([]);
  const [reports, setReports] = useState<ReportModel[]>([]);

  const getReportsList = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/admin/sites/${siteId}/plots/${plot.id}/reports`
      );

      setReports(data);
    } catch (error) {}
  }, [siteId, plot.id]);

  useEffect(() => {
    getReportsList();
  }, [getReportsList]);

  useEffect(() => {
    getSectionsCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  const getSectionsCount = () => {
    const regime = plot.regime;

    var ext = 0,
      int_1 = 0,
      int_2 = 0,
      com = 0,
      roof = 0;
    var ext_comp = 0,
      int_1_comp = 0,
      int_2_comp = 0,
      com_comp = 0,
      roof_comp = 0;

    Object.entries(regime)
      .filter((element) => element[0].startsWith("floor_"))
      .forEach((room) => {
        room[1].forEach((roomI) => {
          if (roomI.startsWith("external_")) {
            ext++;
            if (
              reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0] &&
                  element.completed
              )
            ) {
              ext_comp++;
            }
          } else if (roomI.startsWith("roof_")) {
            roof++;
            if (
              reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0] &&
                  element.completed
              )
            ) {
              roof_comp++;
            }
          } else if (roomI.startsWith("internals_1_")) {
            int_1++;
            if (
              reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0] &&
                  element.completed
              )
            ) {
              int_1_comp++;
            }
          } else if (roomI.startsWith("internals_2_")) {
            int_2++;
            if (
              reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0] &&
                  element.completed
              )
            ) {
              int_2_comp++;
            }
          } else if (roomI.startsWith("commissioning_")) {
            com++;
            if (
              reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0] &&
                  element.completed
              )
            ) {
              com_comp++;
            }
          }
        });
      });

    const nSections = [];
    if (ext > 0) {
      nSections.push({
        id: "external",
        title: "External",
        total: ext,
        completed: ext_comp,
      });
    }

    if (roof > 0) {
      nSections.push({
        id: "roof",
        title: "Roof",
        total: roof,
        completed: roof_comp,
      });
    }

    if (int_1 > 0) {
      nSections.push({
        id: "internals_1",
        title: "Internals 1st fix",
        total: int_1,
        completed: int_1_comp,
      });
    }

    if (int_2 > 0) {
      nSections.push({
        id: "internals_2",
        title: "Internals 2nd fix",
        total: int_2,
        completed: int_2_comp,
      });
    }

    if (com > 0) {
      nSections.push({
        id: "commissioning",
        title: "Commissioning",
        total: com,
        completed: com_comp,
      });
    }

   

    setSections(nSections);
  };

  return (
    <div>
      {sections.map((rep) => (
        <Section {...rep} plot={plot} regime={plot.regime} reports={reports} key={rep.id} />
      ))}
    </div>
  );
};
interface SectionCompProps extends ReportSectionType {
  reports: ReportModel[];
  plot: PlotModel;
  regime: { [k: string]: string[] };
}
const Section: React.FC<SectionCompProps> = ({
  id,
  title,
  total,
  completed,
  regime,
  reports,
  plot,
}) => {
  const [open, setOpen] = useState(false);

  const color = useMemo<string>(() => {
    if (completed === total) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, total]);

  const floors = useMemo<any>(() => {
    let items: any[] = [];

    Object.entries(regime).forEach((floor) => {
      floor[1].forEach((opt) => {
        if (opt.startsWith(`${id}_`)) {
          const reportI = reports.filter(
            (rpt) => rpt.type_id === opt && rpt.room_or_comm_id === floor[0]
          );

          items.push({
            id: `${floor[0]}_${opt}`,
            title: opt,
            group: floor[0],
            complete: reportI.length > 0 ? reportI[0].completed : false,
            report: reportI.length > 0 ? reportI[0] : null,
          });
        }
      });
    });

    return items
      .sort((a, b) => a.group.localeCompare(b.group))
      .reduce((groups, floor) => {
        const { group } = floor;
        groups[group] = groups[group] ?? [];
        groups[group].push(floor);
        return groups;
      }, {});
  }, [id, regime, reports]);

  return (
    <div className="max-w-6xl mb-6">
      <div
        className={`flex bg-${color}-300 p-4 rounded-md items-center justify-between cursor-pointer hover:bg-${color}-300`}
        role="button"
        onClick={() => setOpen(!open)}
      >
        <h3>{title}</h3>{" "}
        <div
          className={`flex items-center justify-center border-2 border-${color}-800 rounded-md text-sm font-medium px-3 py-1 text-${color}-700 font-medium`}
        >
          {completed}/{total}
        </div>
      </div>
      {open && (
        <div className="ml-10">
          {Object.entries(floors).map((floor) => (
            <Floor plot={plot} id={floor[0]} items={floor[1]} />
          ))}
        </div>
      )}
    </div>
  );
};

const Floor: React.FC<any> = ({ id, items, plot }) => {
  const [open, setOpen] = useState(false);
  const completed = useMemo(() => {
    return items.filter((itm: any) => itm.complete).length;
  }, [items]);

  const color = useMemo<string>(() => {
    if (completed === items.length) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, items.length]);

  return (
    <div className="max-w-6xl my-6">
      <div
        className={`flex bg-${color}-300 p-4 rounded-md items-center justify-between cursor-pointer hover:bg-${color}-300`}
        role="button"
        onClick={() => setOpen(!open)}
      >
        <h3>{ plot.floors_no === 0 ? 'Single Floor' : getFloorName(id)}</h3>{" "}
        <div
          className={`flex items-center justify-center border-2 border-${color}-800 rounded-md text-sm font-medium px-3 py-1 text-${color}-700 font-medium`}
        >
          {completed}/{items.length}
        </div>
      </div>
      {open && (
        <div className="ml-10">
          {items.map((itm: any) => (
            <div className="bg-white rounded-md p-3 my-2 flex items-center justify-between">
              <div>{getSectionName(itm.title)}</div>
              <input type="checkbox" checked={itm.complete} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlotProgressTab;
