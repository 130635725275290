import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import axios from "axios";
import CoverPage from "./pages/Cover";
import ProgressPage from "./pages/Progress";
import ReportPage from "./pages/Report";
import { getRegimeOptions } from "../../utils/regime";

Font.register({
  family: "Roboto",
  src: "https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2",
});

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

interface PlotReportScreenProps {}

const PlotReportScreen: React.FC<PlotReportScreenProps> = () => {
  let { plotId } = useParams();

  const [loading, setLoading] = useState(true);

  const [report, setReport] = useState<any>({});

  const getPlotReport = useCallback(async () => {
    setLoading(true);

    await getRegimeOptions();
    try {
      const { data } = await axios.get(`exports/${plotId}`);
      setReport(data);
    } catch (error) {
      console.log(error);
      alert(error);
    }
    setLoading(false);
  }, [plotId]);

  useEffect(() => {
    getPlotReport();
  }, [getPlotReport, plotId]);

  const mobileDownload = () => {};

  function getFormattedDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
  
    return `${day}${month}${year}`;
  }


  if (loading || !report)
    return (
      <div>
        Loading plot report
        <button onClick={mobileDownload}></button>
      </div>
    );


  // return <div>{JSON.stringify(report)}</div>

  // if (isMobile)
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <PDFDownloadLink
        document={
          <Document title={`${report.site.name}_${report.name}_PlotFireReport_${getFormattedDate()}`} creator="PlotFire">
            <CoverPage report={report} />
            <ProgressPage report={report} />
            <ReportPage report={report} />
          </Document>
        }
        fileName={`${report.site.name}_${report.name}_PlotFireReport_${getFormattedDate()}`}
        className="bg-[#ff1616] text-white p-4 rounded-md font-medium"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Report"
        }
      </PDFDownloadLink>
    </div>
  );
  // return (
  //   <PDFViewer style={styles.viewer}>
  //     <Document title={`${report.name} - Report`} creator="PlotFire">
  //       <CoverPage report={report} />
  //       <ProgressPage report={report} />
  //       <ReportPage report={report} />
  //     </Document>
  //   </PDFViewer>
  // );
};

export default PlotReportScreen;
