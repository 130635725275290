/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlotModel } from "../../types/api";
import axios from "../../plugins/axios";
import EditPlotRegimeForm from "../../forms/EditPlotRegimeForm";
import ShowInflightPlotScreen from "./show";
import { IoArrowBackOutline } from "react-icons/io5";

const EditPlotScreen: React.FC = () => {
  let { siteId, plotId } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plot, setPlot] = useState<PlotModel | null>();

  const getPlotById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PlotModel>(
        `/admin/sites/${siteId}/plots/${plotId}`
      );
      setPlot(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
    
  }, [plotId, siteId]);

  useEffect(() => {
    getPlotById();
  }, [getPlotById]);

  if (!plot) return null;

  return (
    <div>
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black p-6"
        onClick={() => {
          navigate(`/sites/${siteId}`);
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to plots list
      </div>
      {plot.active ? (
        <ShowInflightPlotScreen
          plot={plot!}
          siteId={siteId}
          getPlotById={getPlotById}
        />
      ) : (
        <EditPlotRegimeForm plot={plot!} siteId={siteId} />
      )}
    </div>
  );
};

export default EditPlotScreen;
