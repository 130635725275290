import React, { useMemo } from "react";

interface RegimeConfigInputGroupProps {
  value: string[];
  setValue: (v: string[]) => void;
  name: string;
  items: { id: string; name: string }[];
}

const groupNames: any = {
  external: "External",
  roof: "Roof",
  internals_1: "Internals 1st Fix",
  internals_2: "Internals 2nd Fix",
  commissioning: "Commissioning",
};
const RegimeConfigInputGroup: React.FC<RegimeConfigInputGroupProps> = ({
  value,
  setValue,
  items,
  name,
}) => {
  const selected = useMemo(() => {
    let currentCheck = [];

    const selectedItems = items
      .filter((ii) => value.includes(ii.id))
      .map((i2) => i2.id);

    currentCheck = [...selectedItems];

    if (currentCheck.length === items.length)
      currentCheck = [...currentCheck, "group"];

    return currentCheck;
  }, [items, value]);

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start text-[#ff1616] font-medium border-b w-full pb-2">
          <input
            type="checkbox"
            checked={selected.includes("group")}
            onChange={(e) => {
              if (e.target.checked) {
                setValue([
                  ...value,
                  ...items
                    .map((i2) => i2.id)
                    .filter((ff) => !value.includes(ff)),
                ]);
              } else {
                setValue(
                  value.filter((ii2) => !items.map((i2) => i2.id).includes(ii2))
                );
              }
            }}
            className="mr-2 w-4 h-4 text-[#ff1616] bg-gray-100 rounded border-gray-300 focus:ring-[#fe5151] dark:focus:ring-[#fe5151] dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600"
          />
          {groupNames[name]}{" "}
        </div>
      </div>
      <div className="my-2">
        {items.map((itm) => (
          <div
            className="flex items-center justify-start p-2 pl-0"
            key={`${name}-${itm.id}`}
          >
            <input
              type="checkbox"
              onChange={(e) => {
                let newVal = value;
                if (e.target.checked) {
                  if (!newVal.includes(itm.id)) {
                    newVal = [...newVal, itm.id];
                  }
                } else {
                  newVal = newVal.filter((nvv) => nvv !== itm.id);
                }

                setValue(newVal);
              }}
              checked={selected.includes(itm.id)}
              className="mr-2 w-4 h-4 text-[#ff1616] bg-gray-100 rounded border-gray-300 focus:ring-[#fe5151] dark:focus:ring-[#fe5151] dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600 disabled:cursor-not-allowed"
            />
            <label>{itm.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegimeConfigInputGroup;
