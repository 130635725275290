import React, { useCallback, useEffect, useState } from "react";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import CognitoUser from "../../../components/CognitoUser";
import { toast } from "react-toastify";
import withApiUser from "../../../utils/useUserCompanyRole";
import { userState } from "../../../atoms/user";
import { useRecoilValue } from "recoil";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

const PlotUsersTab: React.FC<PlotDetailsTabProps> = ({
  plot,
  getPlot,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<{ id: number }[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const authUser = useRecoilValue(userState);
  const [addedUsers, setAddedUsers] = useState(plot.users ?? []);

  const getUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await axios.get<{ id: number }[]>(`/admin/users`);
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);
    try {
      await axios.put(`/admin/sites/${siteId}/plots/${plot.id}`, {
        ...plot,
        users: JSON.stringify(addedUsers),
        regime: JSON.stringify(plot.regime),
      });
      toast("Saved plot users", { type: "success" });
      getPlot();
    } catch (error) {
      toast("Failed to save plot users", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form className="max-w-3xl" onSubmit={onSubmit}>
      <div className="p-5 bg-[#ff1616] bg-opacity-50 italic text-gray-600 border-2 border-opacity-50 border-[#fe5151] rounded-md">
        You can manage individual users access to plots here. This list excludes
        admin users as users with the admin role have access to all plots.
      </div>

      <div className="max-w-4xl rounded-md overflow-hidden">
        {users.length > 0 ? (
          <>
            {users.map((user, index) => (
              <CognitoUser
                type="admin"
                userId={user.id}
                render={(userD) => (
                  <label
                    htmlFor="manage-trades"
                    className={`bg-gray-200 p-5 my-5 rounded-md flex items-center justify-between`}
                  >
                    <div className="col-span-3">
                      {userD.name}({userD.email})
                    </div>
                    {userD.owner === true ? (
                      <p className="text-gray-400 font-medium">ADMIN</p>
                    ) : (
                      <input
                        id="manage-trades"
                        type="checkbox"
                        checked={addedUsers.includes(user.id)}
                        onChange={() => {
                          if (addedUsers.includes(user.id)) {
                            setAddedUsers((prevUsers) =>
                              prevUsers.filter((prU) => prU !== user.id)
                            );
                          } else {
                            setAddedUsers([...addedUsers, user.id]);
                          }
                        }}
                        className="w-4 h-4 text-[#ff1616] bg-white rounded border-white focus:ring-[#fe5151] dark:focus:ring-[#fe5151] dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-white mr-4"
                      />
                    )}
                  </label>
                )}
              />
            ))}
          </>
        ) : (
          <>
            {loadingUsers ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Users List
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                You do not currently have any active users, invite a user below.
              </div>
            )}
          </>
        )}
      </div>

      {authUser?.role !== "user" && (
        <button
          type="submit"
          disabled={loading}
          className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
        >
          {loading ? "Loading..." : "Save Plot users"}
        </button>
      )}
    </form>
  );
};

export default withApiUser(PlotUsersTab);
