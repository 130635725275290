import React, { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
interface ListViewHeaderProps {
  title: string;
  onCreate?: () => void;
  showSearch?: boolean;
  onExport?: () => void;
  actions?: any;
}

const ListViewHeader: React.FC<ListViewHeaderProps> = ({
  title,
  showSearch = false,
  onExport,
  onCreate,
  actions,
}) => {
  const [search, setSearch] = useCustomSearchParams();
  const [term, setTerm] = useState(search.search ?? "");

  return (
    <div className="w-full p-6">
      <div className="flex items-center justify-between w-full mb-2">
        <h1 className="text-2xl font-medium">{title}</h1>
        <div>
          {!!onCreate && (
            <button
              onClick={onCreate}
              className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
            >
              Create New
            </button>
          )}
        </div>
      </div>
      {(showSearch || !!actions) && (
        <div className="grid grid-cols-2 gap-4 mt-8">
          <div>
            {showSearch && (
              <form
                className="bg-white w-full h-10 flex items-center justify-between rounded-md overflow-hidden"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (term.length >= 2 || term.length === 0) {
                    setSearch({ ...search, search: term, page: 1 });
                  }
                }}
              >
                <input
                  placeholder="Enter search term (3 characters or more)"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  className="w-full h-10 p-1 pl-6 focus:outline-none"
                />
                <button
                  type="submit"
                  disabled={term.length < 2 && term.length !== 0}
                  className="bg-[#ff1616] text-white hover:bg-[#fe5151] h-full px-4 rounded-md shadow-sm text-sm"
                >
                  <IoSearchOutline size={18} />
                </button>
              </form>
            )}
          </div>
          <div>{actions}</div>
        </div>
      )}
    </div>
  );
};

export default ListViewHeader;
