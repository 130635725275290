import axios from "../../plugins/axios";
import React, { useRef, useState } from "react";
import ApiImage from "../ApiImage";

interface ImageUploadFieldProps {
  value: string | null;
  onChange: (v: string) => void;
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  value,
  onChange,
}) => {
  const inputRef = useRef<any>(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const onImageChange = async (event: any) => {
    setLoadingImage(true);

    try {
      const { data } = await axios.post("/files", {
        filename: event.target.files[0].name,
      });

      const formData = new FormData();

      Object.entries(data.request_data.fields).forEach(([k, v]: any) => {
        formData.append(k, v);
      });

      formData.append("file", event.target.files[0]); // The file has be the last element

      await fetch(data.request_data.url, {
        method: "POST",
        body: formData,
      });

      onChange(data.path);
    } catch (error) {
      console.log(error);
    }
    setLoadingImage(false);
  };

  return (
    <div className="mt-1 relative">
      {value ? (
        <div
          className="w-24 h-24 flex items-center justify-center text-center hover:border-black cursor-pointer rounded-md text-xs overflow-hidden bg-white"
          onClick={() => {
            if (!loadingImage) {
              inputRef.current!.click();
            }
          }}
        >
          <ApiImage
            imageUrl={value}
            imageProps={{ className: "w-full h-full object-contain" }}
            loadingContainer={
              <div className="w-24 h-24 flex items-center justify-center text-center hover:border-black cursor-pointer rounded-md text-xs bg-black bg-opacity-70 absolute top-0 left-0 text-white">
                Loading...
              </div>
            }
          />
        </div>
      ) : (
        <div
          className="border border-gray-300 border-dashed w-24 h-24 flex items-center justify-center text-center hover:border-black cursor-pointer rounded-md text-xs px-6"
          onClick={() => {
            if (!loadingImage) {
              inputRef.current!.click();
            }
          }}
        >
          Choose Image
        </div>
      )}

      {loadingImage && (
        <div className="w-24 h-24 flex items-center justify-center text-center hover:border-black cursor-pointer rounded-md text-xs bg-black bg-opacity-70 absolute top-0 left-0 text-white">
          Loading...
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        name="image_upload_field"
        className="hidden"
        onChange={onImageChange}
      />
    </div>
  );
};

export default ImageUploadField;
