import React, { useCallback, useEffect, useState } from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import CoverStatusItem from "../parts/CoverStatus";
import { getApiFileURL } from "../../../utils/files";
interface CoverPageProps {
  report: any;
}

const styles = StyleSheet.create({
  coverBody: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 40,
  },

  coverLogosView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  coverLogosLogo: {
    width: 40,
    height: 40,
    objectFit: "contain",
  },
  coverTitle: {
    fontSize: 36,
  },
  coverSubtitle: {
    color: "darkgray",
  },
  twoRowStatus: {
    display: "flex",
    flexDirection: "row",
  },
  logoTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "row",
    marginTop: 4,
    fontSize: 12,
    fontWeight: 'bold'
  }
});

const CoverPage: React.FC<CoverPageProps> = ({ report }) => {
  return (
    <Page size="A4" style={styles.coverBody}>
      <View style={styles.coverLogosView}>
        {/* <CompanyLogo img={report.company_logo} /> */}
        
        {report.company_logo !== '' ? <Image src={report.company_logo} style={styles.coverLogosLogo} /> : <View></View>}
        <View>
          <Image src="/logo.png" style={styles.coverLogosLogo} />
          <View style={styles.logoTextContainer}><Text>Plot</Text><Text style={{ color: 'red'}}>Fire</Text></View>
        </View>
      </View>
      <View>
        <Text style={styles.coverTitle}>{report.name}</Text>
        <Text style={styles.coverSubtitle}>{report.site.location}</Text>
      </View>
      <View>
        <CoverStatusItem title={report.name} content={report.site.name} />
        <CoverStatusItem
          title="Prepared by:"
          content={`${report.created_by} (${report.company})`}
        />
        <View style={styles.twoRowStatus}>
          <CoverStatusItem title="Date Created:" content={report.created_at} />
          <View style={{ width: 60, height: 10 }}></View>
          {report.reports.length > 0 && (
            <CoverStatusItem
              title="Date Updated:"
              content={new Date(
                report.reports[report.reports.length - 1].created_at
              ).toLocaleDateString()}
            />
          )}
        </View>
      </View>
    </Page>
  );
};

export const CompanyLogo = ({ img }: { img?: string }) => {
  const [img2, setImage2] = useState("");

  const getFile = useCallback(async () => {
    const val = await getApiFileURL(img);
    setImage2(val);
  }, [img]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  if (!img) return <Image src="#" style={styles.coverLogosLogo} />;

  if (!img2) return <Image src="#" style={styles.coverLogosLogo} />;

  return <Image src={img2} style={styles.coverLogosLogo} />;
};

export default CoverPage;
