import React, { useCallback, useEffect, useMemo, useState } from "react";
import ListViewHeader from "../../components/ListViewHeader";
import { ActivityLogModel, PaginatedType } from "../../types/api";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import axios from "../../plugins/axios";
import Table from "../../components/Table";

interface CompanyEventLogPageProps {}

const CompanyEventLogPage: React.FC<CompanyEventLogPageProps> = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logItems, setLogItems] = useState<ActivityLogModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getLogItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<ActivityLogModel[]>>(
        "/admin/activity-log",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
          },
        }
      );
      setLogItems(data.items);
      setTotal(data.total);
    } catch (error) {
      setLogItems([]);
      setTotal(0);
      setError(true);
    }
    setLoading(false);
  }, [pagination]);

  useEffect(() => {
    getLogItems();
  }, [getLogItems, pagination]);

  return (
    <div>
      <ListViewHeader title="Event Log" />

      <Table
        total={total}
        loading={loading}
        error={error}
        rows={logItems}
        pagination={pagination}
        headers={[
          "Type",
          "Description",
          "Caused By",
          "On Model",
          "Timestamp",
          "",
        ]}
        messages={{
          noResults:
            "Activity log is empty, actions by users within the mobile app will appear here.",
          apiFailed: "Failed to load event log, only users with admin access can access the event log!",
        }}
        renderRow={(logItem) => {
          return (
            <tr
              key={logItem.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">{logItem.log_name}</td>
              <td className="py-2">{logItem.description}</td>
              <td className="py-2">
                {logItem.causer_type}(<strong>{logItem.causer_id}</strong>)
              </td>
              <td className="py-2">
                {logItem.subject_type}(<strong>{logItem.subject_id}</strong>)
              </td>

              <td className="py-2">
                {new Date(logItem.created_at).toLocaleDateString()}
              </td>
              <td></td>
            </tr>
          );
        }}
      />
    </div>
  );
};

export default CompanyEventLogPage;
