import React from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
interface PaginatorProps {
  total?: number;
  currentPage?: number;
  perPage?: number;
}

const Paginator: React.FC<PaginatorProps> = ({
  total = 0,
  currentPage = 1,
  perPage = 10,
}) => {
  const [search, setSearch] = useSearchParams();

  const changePage = (page: number) => {
    setSearch({ ...search, page: page.toString() });
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t sm:px-6 rounded-b-md border-gray-100">
      <div className="flex-1 flex justify-between sm:hidden">
        {currentPage !== 1 ? (
          <div
            role="button"
            onClick={() => {
              changePage(currentPage - 1);
            }}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {" "}
            Previous{" "}
          </div>
        ) : (
          <div></div>
        )}
        {total / perPage > currentPage ? (
          <div
            role="button"
            onClick={() => {
              changePage(currentPage + 1);
            }}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {" "}
            Next{" "}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing Page
            <span className="font-medium mx-1 text-black">{currentPage}</span>
            of
            <span className="font-medium mx-1 text-black">
              {Math.ceil(total / perPage)}
            </span>
            ,<span className="font-medium mx-1 text-black">{total}</span>
            rows in total
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <div
              role="button"
              onClick={() => {
                if (currentPage !== 1) changePage(currentPage - 1);
              }}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                currentPage !== 1 ? "" : "opacity-50 cursor-not-allowed"
              }`}
            >
              <span className="sr-only">Previous</span>

              <IoChevronBack size={20} />
            </div>

            {Math.ceil(total / perPage) > 6 ? (
              <>
                <div
                  role="button"
                  aria-current="page"
                  onClick={() => {
                    if (currentPage !== 1) {
                      changePage(1);
                    }
                  }}
                  className={`z-10 border-gray-300   text-gray-700 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {"First"}
                </div>
                {currentPage !== 1 &&
                  currentPage !== Math.ceil(total / perPage) && (
                    <div
                      role="button"
                      aria-current="page"
                      className={`z-10 border-gray-300 bg-[#fe515124] text-[#ff1616] hover:cursor-default relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                    >
                      {" "}
                      {currentPage}{" "}
                    </div>
                  )}
                <div
                  role="button"
                  aria-current="page"
                  onClick={() => {
                    if (Math.ceil(total / perPage) !== currentPage) {
                      changePage(Math.ceil(total / perPage));
                    }
                  }}
                  className={`z-10 border-gray-300   text-gray-700 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                  {"Last"}
                </div>
              </>
            ) : (
              <>
                {Array.from(Array(Math.ceil(total / perPage))).map(
                  (page, index) => (
                    <div
                      key={index}
                      role="button"
                      aria-current="page"
                      onClick={() => {
                        if (index + 1 !== currentPage) {
                          changePage(index + 1);
                        }
                      }}
                      className={`z-10 border-gray-300 ${
                        index + 1 === currentPage
                          ? "bg-[#fe515124] text-[#ff1616] hover:cursor-default"
                          : " text-gray-700"
                      }  relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                    >
                      {" "}
                      {index + 1}{" "}
                    </div>
                  )
                )}
              </>
            )}

            <div
              role="button"
              onClick={() => {
                if (total / perPage > currentPage) {
                  changePage(currentPage + 1);
                }
              }}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                total / perPage > currentPage
                  ? ""
                  : "opacity-50 cursor-not-allowed"
              }`}
            >
              <span className="sr-only">Next</span>
              <IoChevronForward size={20} />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
