import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormProps } from "../types/app";
import axios from "../plugins/axios";
import Button from "../components/Button";

type Inputs = {
  name: string;
  construction_method: string;
  floors_no: number;
  communals_no: number;
  plots_no: number;
};
interface CreateBlockFormProps extends FormProps {
  siteId: number;
}
const CreateBlockForm: React.FC<CreateBlockFormProps> = ({
  onCompleted,
  siteId,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      construction_method: "",
      floors_no: 0,
      communals_no: 0,
      plots_no: 0,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      setLoading(true);
      try {
        const { data } = await axios.post<{
          success: boolean;
          block_id: number;
        }>(`/admin/sites/${siteId}/blocks`, formData);
        // setSite(data);
        setError(false);
        navigate(`/sites/${siteId}/blocks/${data.block_id}`);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Create new Block</h2>
      <div className="my-4">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Block Name *
        </label>
        <input
          id="name"
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Site Name (3 characters min)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Site name is required and must be more than 3 characters
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="location" className="text-sm font-medium mb-2">
          Construction Method
        </label>
        <select
          id="construction_method"
          {...register("construction_method", { required: true, minLength: 1 })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#fe5151] focus:border-[#fe5151] block w-full p-2.5 placeholder:text-gray-400"
          placeholder="Choose a construction method"
        >
          <option selected disabled>
            Choose a construction method
          </option>
          <option value="timber_frame">Timber Frame</option>
          <option value="masonry">Masonry</option>
          <option value="steel_frame">Steel Frame</option>
        </select>
        {errors.construction_method && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Construction Method is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="registration_date" className="text-sm font-medium mb-2">
          Floors
        </label>
        <input
          id="floors_no"
          {...register("floors_no", {
            required: true,
            valueAsNumber: true,
            min: 0,
            max: 10,
          })}
          max={10}
          min={0}
          type="number"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.floors_no && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Floors is required, must be a value between 1 and 5
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="communals_no" className="text-sm font-medium mb-2">
          Communals
        </label>
        <input
          id="communals_no"
          {...register("communals_no", {
            required: true,
            valueAsNumber: true,
            min: 0,
          })}
          min={0}
          type="number"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.communals_no && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Communals is required, must be a number greater than 0
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="plots_no" className="text-sm font-medium mb-2">
          Plots
        </label>
        <input
          id="plots_no"
          {...register("plots_no", {
            required: true,
            valueAsNumber: true,
            min: 0,
          })}
          min={0}
          type="number"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.plots_no && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Plots is required, must be a number greater than 0
          </span>
        )}
      </div>

      {error && (
        <div className="font-medium text-red-600">Failed to submit form</div>
      )}

      <Button title="Continue" loading={loading} disabled={loading} />
    </form>
  );
};

export default CreateBlockForm;
