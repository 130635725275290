import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import CognitoUser from "../../components/CognitoUser";
import { InviteModel } from "../../types/api";
import InviteUserToCompanyForm from "../../forms/InviteUserToCompanyForm";
import Modal from "../../components/Modal";
import { TiUserDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { useRecoilValue } from "recoil";
import { userState } from "../../atoms/user";

const ManageUsersTab: React.FC = () => {
  const authUser = useRecoilValue(userState);

  const [invites, setInvites] = useState<InviteModel[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const [users, setUsers] = useState<{ id: number; created_at: string }[]>([]);
  const [fullUsersExportList, setFullUsersExportList] = useState<any[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingInvites, setLoadingInvites] = useState(true);

  const getInvites = useCallback(async () => {
    setLoadingInvites(true);
    try {
      const { data } = await axios.get<InviteModel[]>(`/admin/users/invites`);
      setInvites(data);
    } catch (error) {
      setInvites([]);
    }
    setLoadingInvites(false);
  }, []);

  const getUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await axios.get<{ id: number; created_at: string }[]>(
        `/admin/users`
      );
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getInvites();
    getUsers();
  }, [getUsers, getInvites]);

  const deleteUser = async (id: number) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to delete this user, this cannot be undone."
      )
    ) {
      try {
        await axios.delete(`/admin/users/${id}`);
        toast("User Deleted successfully", { type: "success" });
        getInvites();
        getUsers();
      } catch (error) {
        toast("Failed to delete user.", { type: "error" });
      }
    }
  };

  const deleteInvite = async (id: number) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to delete this invite, you will need to resend if you want the user to join your team"
      )
    ) {
      try {
        await axios.delete(`/admin/users/invites/${id}`);
        toast("Invite deleted successfully", { type: "success" });
        getInvites();
        getUsers();
      } catch (error) {
        toast("Failed to delete user.", { type: "error" });
      }
    }
  };

  const dousersExport = (event: any, done: any) => {
    const elements1: any = document.querySelectorAll(`[id^="user-"]`);

    let nArr: object[] = [];

    elements1.forEach((element: any) => {
      nArr = [...nArr, JSON.parse(element.dataset.exportdata)];
    });

    setFullUsersExportList(nArr);

    done();
  };

  return (
    <div>
      <div className="max-w-5xl flex items-center justify-between">
        <h3 className="my-4 font-medium text-lg">Active Users</h3>
        <CSVLink
          filename="UsersExport.csv"
          headers={[
            { label: "Id", key: "id" },
            { label: "Name", key: "name" },
            { label: "Email", key: "email" },
            { label: "Account Owner", key: "owner" },
            { label: "Created At", key: "created_at" },
          ]}
          data={fullUsersExportList}
          asyncOnClick={true}
          onClick={dousersExport}
          className="bg-[#ff1616] p-3 rounded-md text-white block w-36 text-sm text-center mb-3 cursor-pointer hover:bg-[#fe5151]"
        >
          {"Export CSV"}
        </CSVLink>
      </div>
      <div className="max-w-5xl rounded-md overflow-hidden">
        {users.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-8 bg-[#ff1616] text-white font-medium">
              <div className="col-span-3">Name</div>
              <div className="col-span-2">Role</div>
              <div className="col-span-2">Active Since</div>

              <div></div>
            </div>

            {users.map((user, index) => (
              <CognitoUser
                type="admin"
                userId={user.id}
                render={(userD) => (
                  <div
                    id={`user-${user.id}`}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                    } p-3 grid grid-cols-8`}
                    data-exportData={JSON.stringify({ ...user, ...userD })}
                  >
                    <div className="col-span-3">
                      {userD.name}({userD.email})
                    </div>
                    <div className="col-span-2">
                      {userD.owner ? "Admin" : "User"}
                    </div>
                    <div className="col-span-2">
                      {new Date(user.created_at).toLocaleString()}
                    </div>

                    <div className="text-right">
                      {" "}
                      {authUser?.role !== "user" && (
                        <button
                          className="text-red-500 hover:text-red-600"
                          title="Remove User"
                          onClick={() => deleteUser(user.id)}
                        >
                          <TiUserDelete size={20} />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              />
            ))}
          </>
        ) : (
          <>
            {loadingUsers ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Users List
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                You do not currently have any active users, invite a user below.
              </div>
            )}
          </>
        )}
      </div>
      <hr className="my-10" />

      <div className="max-w-5xl flex items-center justify-between">
        <h3 className="my-4 font-medium text-lg">Pending Invites</h3>
        <CSVLink
          filename="PendingInvitesExport.csv"
          headers={[
            { label: "Id", key: "id" },
            { label: "Email", key: "email" },
            { label: "Account Owner", key: "owner" },
            { label: "Created At", key: "created_at" },
          ]}
          data={invites}
          className="bg-[#ff1616] p-3 rounded-md text-white block w-36 text-sm text-center mb-3 cursor-pointer hover:bg-[#fe5151]"
        >
          {"Export CSV"}
        </CSVLink>
      </div>
      <div className="max-w-5xl rounded-md overflow-hidden mb-10">
        {invites.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-8 bg-[#ff1616] text-white font-medium">
              <div className="col-span-3">Email</div>
              <div className="col-span-2">Role</div>
              <div className="col-span-2">Invite Sent at</div>

              <div></div>
            </div>
            {invites.map((invitee, index) => (
              <div
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                } p-3 grid grid-cols-8`}
              >
                <div className="col-span-3">{invitee.email}</div>
                <div className="col-span-2">
                  {invitee.owner ? "Admin" : "User"}
                </div>
                <div className="col-span-2">
                  {new Date(invitee.created_at).toLocaleString()}
                </div>

                <div className="text-right">
                  {authUser?.role !== "user" && (
                    <button
                      className="text-red-500 hover:text-red-600"
                      title="Remove Invite"
                      onClick={() => deleteInvite(invitee.id)}
                    >
                      <TiUserDelete size={20} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {loadingInvites ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Invites
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                No pending invites
              </div>
            )}
          </>
        )}
      </div>

      {authUser?.role !== "user" && (
        <button
          className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
          onClick={() => setShowAdd(true)}
        >
          Invite User
        </button>
      )}
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <InviteUserToCompanyForm
          onCompleted={async () => {
            await getUsers();
            await getInvites();

            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ManageUsersTab;
