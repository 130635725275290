import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Button from "../components/Button";
import RegimeConfigInput from "../components/Inputs/RegimeConfigInput";
import { PlotModel } from "../types/api";
import axios from "../plugins/axios";
import { useNavigate } from "react-router-dom";

interface EditPlotRegimeFormProps {
  plot: PlotModel;
  siteId: any;
}

type Inputs = {
  regime: { [k: string]: string[] };
};

const EditPlotRegimeForm: React.FC<EditPlotRegimeFormProps> = ({
  plot,
  siteId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      regime: plot.regime,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      await axios.put<{
        success: boolean;
      }>(`/admin/sites/${siteId}/plots/${plot.id}`, {
        ...plot,
        regime: JSON.stringify(formData.regime),
        users: JSON.stringify(plot.users),
      });
      navigate(0);
    } catch (error) {}
    setLoading(false);
  };

  const onPublish = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to continue, once the regime is published the config cannot be edited"
      )
    ) {
      setPublishLoading(true);
      try {
        await axios.put<{
          success: boolean;
        }>(`/admin/sites/${siteId}/plots/${plot.id}`, {
          ...plot,
          active: true,
          regime: JSON.stringify(plot.regime),
          users: JSON.stringify(plot.users),
        });
        navigate(0);
      } catch (error) {}
      setPublishLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-[98%] mx-auto my-10">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-medium mb-4">Edit Plot Regime</h2>
        {/* <div>
          <Button
            title="Save Draft"
            loading={loading}
            disabled={loading}
            color="green"
          />
          <Button
            title="Publish"
            loading={publishLoading}
            disabled={publishLoading}
            type="button"
            onClick={onPublish}
          />
        </div> */}
      </div>

      <div className="my-6">
        <label htmlFor="registration_date" className="text-sm font-medium mb-2">
          Regime
        </label>

        <Controller
          control={control}
          name="regime"
          render={({ field: { onChange, value } }) => (
            <RegimeConfigInput
              floors={plot.floors_no}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.regime && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Regime is required
          </span>
        )}
      </div>

      <Button
        title="Save Draft"
        loading={loading}
        disabled={loading}
        color="green"
      />
      <Button
        title="Publish"
        loading={publishLoading}
        disabled={publishLoading || isDirty}
        type="button"
        onClick={onPublish}
      />
      {isDirty && (
        <div>
          You need to save changes before you can confirm your plot regime
        </div>
      )}
    </form>
  );
};

export default EditPlotRegimeForm;
