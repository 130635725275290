import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlotModel, ReportModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { getFloorName, getSectionName } from "../../../utils/stringUtil";
import { CSVLink } from "react-csv";
import { getApiFileURL } from "../../../utils/files";

interface PlotReportsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

const PlotReportsTab: React.FC<PlotReportsTabProps> = ({ siteId, plot }) => {
  const [reports, setReports] = useState<ReportModel[]>([]);

  const getReportsList = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/admin/sites/${siteId}/plots/${plot.id}/reports`
      );

      setReports(data);
    } catch (error) {}
  }, [siteId, plot.id]);

  useEffect(() => {
    getReportsList();
  }, [getReportsList]);

  const csvData = useMemo(() => {
    return [
      ["Section", "Subsection", "Details", "Geotag", "Completed", "Created At"],
      ...reports.map((report) => [
        getSectionName(report.type_id),
        plot?.floors_no === 0 ? 'Single Floor' : getFloorName(report.room_or_comm_id),
        report.details,
        report.geo_tag,
        report.completed ? "Yes" : "No",
        report.created_at,
      ]),
    ];
  }, [reports]);

  return (
    <div className="py-4 px-0 relative">
      <div className="relative" style={{ minHeight: 300 }}>
        <CSVLink
          data={csvData}
          filename="Reports"
          className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md"
        >
          CSV Download
        </CSVLink>
        <table className="table-auto w-full text-left bg-white rounded-t-md mt-6">
          <thead className="mb-2 h-12 border-b border-gray-100">
            <tr className="text-gray-400 font-light">
              <th className="p-2 pl-6">Section</th>
              <th>Subsection</th>
              <th>Details</th>

              <th>Images</th>
              <th>Attachment</th>
              <th>Geotag</th>

              <th>Completed</th>
              <th>Created At</th>

              {/* {headers.map((header, index) => {
                if (index === 0) return <th className="p-2 pl-6">{header}</th>;
                if (header === "") return <th className="w-10"></th>;
                return <th>{header}</th>;
              })} */}
            </tr>
          </thead>
          <tbody>
            {reports.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500 text-sm"
                >
                  <td className="py-3 pl-6 max-w-[150px] font-medium text-black">
                    {getSectionName(row.type_id)}
                  </td>
                  <td className="py-2 font-medium">
                    {plot?.floors_no === 0 ? 'Single Floor' : getFloorName(row.room_or_comm_id)}
                  </td>
                  <td className="py-2 max-w-[180px] text-sm">{row.details}</td>

                  <td className="py-2 flex flex-wrap max-w-[150px]">
                    <RowAttachments row={row} />
                  </td>
                  <td className="py-2 max-w-[120px] ">
                    <RowFile file={row.file} />
                  </td>

                  <td className="py-2">{row.geo_tag}</td>

                  <td className="py-2">{row.completed ? "Yes" : "No"}</td>
                  <td className="py-2">
                    {new Date(row.created_at).toLocaleDateString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RowAttachments = ({ row }: { row: ReportModel }) => {
  if (!row.image_1 && !row.image_2 && row.image_3) return <>-</>;

  return (
    <>
      <RowImage img={row.image_1} />
      <RowImage img={row.image_2} />
      <RowImage img={row.image_3} />
      <RowImage img={row.image_4} />
      <RowImage img={row.image_5} />
      <RowImage img={row.image_6} />
    </>
  );
};

export const RowImage = ({ img }: { img?: string }) => {
  const [img2, setImage2] = useState("");

  const getFile = useCallback(async () => {
    const val = await getApiFileURL(img);
    setImage2(val);
  }, [img]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  if (!img) return <></>;

  if (!img2) return <></>;

  return (
    <a className="m-1" href={img2} target={"_blank"} rel="noreferrer">
      <img src={img2} className="w-10 h-10 object-cover rounded-md" alt="" />
    </a>
  );
};

const RowFile = ({ file }: { file?: string }) => {
  const [fileU, setFileU] = useState("");

  const getFile = useCallback(async () => {
    const val = await getApiFileURL(file);

    setFileU(val);
  }, [file]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  if (!file) return <></>;

  if (!fileU) return <></>;

  return (
    <a
      className="text-[#ff1616] text-xs max-w-[200px] block"
      href={fileU}
      target={"_blank"}
      rel="noreferrer"
    >
      {file.split("/")[file.split("/").length - 1]}
    </a>
  );
};

export default PlotReportsTab;
