import React from "react";
import { BiCommentError } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const FailedToLoadDetails: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex items-center justify-center flex-col">
      <BiCommentError size={50} />
      <h4 className="text-[#ff1616] font-medium my-4">Failed to load data</h4>
      <p className="max-w-sm text-center text-gray-500">
        This can happen if you do not have access to this resource or the
        resource did not exist. If you think this is an error please contact
        support
      </p>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="mt-6 text-[#ff1616] text-sm font-bold"
      >
        Go Back
      </button>
    </div>
  );
};

export default FailedToLoadDetails;
