import React from "react";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  title: string;
  loading?: boolean;
  disabled?: boolean;
  color?: string;
}

const Button: React.FC<ButtonProps> = ({
  type = "submit",
  onClick,
  title = "Submit",
  loading = false,
  disabled = false,
  color = 'red'
}) => {
  return (
    <button
      type={type}
      onClick={(e) => {
        if (loading || disabled) {
          e.preventDefault();
        } else if (onClick !== undefined) {
          onClick();
        }
      }}
      disabled={disabled}
      className={`focus:outline-none text-white bg-${color}-600 hover:bg-${color}-700 focus:ring-4 focus:ring-${color}-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-${color}-900 disabled:bg-opacity-50 disabled:cursor-not-allowed`}
    >
      {loading ? "Loading..." : title}
    </button>
  );
};

export default Button;
