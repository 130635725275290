import { Auth } from "aws-amplify";
import axios, { AxiosResponse } from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // "X-Requested-With": "XMLHttpRequest",
};

axios.interceptors.request.use(
  async (config) => {
    const newConfig = config;

    try {
      const session = await Auth.currentSession();

      newConfig.headers!["Authorization"] = `Bearer ${session
        .getIdToken()
        .getJwtToken()}`;
      //
    } catch (error) {}
    return newConfig;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (err) => {
    if (err.response.status === 400) {
      switch (err.response.data.detail) {
        case "User not found in system":
          window.location.replace("/user-not-registered");
          break;

        default:
          break;
      }
    }
    // if (err.response.status === 401) {
    //   window.localStorage.clear();
    //   window.location = "/";
    //   return;
    // }

    // if (err.response.status === 503) {
    //   window.location = "/system-maintenance";
    //   return;
    // }

    return Promise.reject(err);
  }
);

export default axios;
