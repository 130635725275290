import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/user";
import ImageUploadField from "../components/Inputs/ImageUploadField";
import axios from "../plugins/axios";
import { CompanyModel } from "../types/api";
import { FormProps } from "../types/app";

type Inputs = {
  name: string;
  logo: string;
};

interface UpdateCompanyFormProps extends FormProps {
  company: CompanyModel;
}

const UpdateCompanyForm: React.FC<UpdateCompanyFormProps> = ({
  company,
  onCompleted,
}) => {
  const user = useRecoilValue(userState);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: company,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data } = await axios.put<{
        success: boolean;
      }>("/admin/company", {
        name: formData.name,
        logo: formData.logo,
      });
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-3xl">
      <h2 className="text-xl font-medium mb-4">Update Company Details</h2>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Company Logo
        </label>

        <Controller
          control={control}
          name="logo"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <ImageUploadField
              value={value}
              onChange={onChange} // send value to hook form
            />
          )}
        />
        {/*
        <input
          id="logo"
          {...register("logo", { required: true, minLength: 3 })}
          placeholder="Enter Company Name (3 characters min)"
          type="text"
          readOnly={user?.role !== "admin"}
          className="mt-1 block w-full rounded-md bg-white border-transparent focus:border-gray-500 focus:ring-0"
        /> */}
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Company name is required and must be more than 3 characters
          </span>
        )}
      </div>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Company Name
        </label>
        <input
          id="name"
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Company Name (3 characters min)"
          type="text"
          readOnly={user?.role !== "admin"}
          className="mt-1 block w-full rounded-md bg-white border-transparent focus:border-gray-500 focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Company name is required and must be more than 3 characters
          </span>
        )}
      </div>
      {user?.role === "admin" && (
        <button
          type="submit"
          className="focus:outline-none text-white bg-[#ff1616] hover:bg-[#fe5151] focus:ring-4 focus:ring-[#fe5151] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-[#fe5151]"
        >
          Save details
        </button>
      )}
    </form>
  );
};

export default UpdateCompanyForm;
