import { getRecoil } from "recoil-nexus";
import { regimeState } from "../atoms/regime";
import { getRegimeOptions } from "./regime";

export const getFloorNameByIndex = (floor: number): string => {
  switch (floor) {
    case 0:
      return "Ground Floor";
    case 1:
      return "1st floor";
    case 2:
      return "2nd floor";
    case 3:
      return "3rd floor";
    case 4:
      return "4th floor";
    case 5:
      return "5th floor";
    default:
      return `Floor: ${floor}`;
  }
};

export const getSectionName = (sectionId: string) => {
  const regimeOptions = getRecoil(regimeState);
  return regimeOptions.find((element) => element["id"] === sectionId)?.name;
};

export const getFloorName = (code: string) => {
  switch (code) {
    case "floor_0":
      return "Ground Floor";
    case "floor_1":
      return "1st Floor";
    case "floor_2":
      return "2nd Floor";
    case "floor_3":
      return "3rd Floor";
    case "floor_4":
      return "4th Floor";
    default:
      return code;
  }
};
