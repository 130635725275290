import axios from "../../../plugins/axios";
import React, { useState } from "react";
import { CompanyModel } from "../../../types/api";

interface CompanyShowActionsTabProps {
  company: CompanyModel;
  reloadCompany: () => Promise<void>;
}

const CompanyShowActionsTab: React.FC<CompanyShowActionsTabProps> = ({
  company,
  reloadCompany,
}) => {
  const [loading, setLoading] = useState(false);
  const renewSuccessClick = async () => {
    setLoading(true);
    try {
      await axios.patch(
        `/superadmin/companies/${company.id}/renewal-status/success`
      );
      await reloadCompany();
    } catch (error) {}
    setLoading(false);
  };
  const renewFailedClick = async () => {
    setLoading(true);

    try {
      await axios.patch(
        `/superadmin/companies/${company.id}/renewal-status/failed`
      );
      await reloadCompany();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <div className="max-w-3xl">
      <div className="p-4 rounded-md bg-white">
        <h3 className="text-xl font-medium">Enterprise</h3>
        {company.enterprise ? (
          <>
            <p className="text-gray-500 text-sm">
              <strong>{company.name}</strong> is a part of the enterprise
              program
            </p>
            {company.enterprise_renewed ? (
              <div
                role="button"
                onClick={() => {
                  if (!loading) {
                    renewFailedClick();
                  }
                }}
                className="bg-red-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
              >
                {loading ? "Loading...." : "Mark as renewal failed"}
              </div>
            ) : (
              <div
                role="button"
                onClick={() => {
                  if (!loading) {
                    renewSuccessClick();
                  }
                }}
                className="bg-green-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
              >
                {loading ? "Loading...." : "Mark as renewal success"}
              </div>
            )}
            <small className="text-gray-600 font-medium">
              Note: clicking the link above will affect access to the app if the
              renewal status is set to failed
            </small>
          </>
        ) : (
          <>
            <p className="text-gray-500 text-sm">
              <strong>{company.name}</strong> is not part of the enterprise
              program, companies created through the superadmin console can be
              set as enterprise.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyShowActionsTab;
