import React, { useCallback, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import axios from "../../plugins/axios";
import { UserModel } from "../../types/api";

interface CognitoUserProps {
  userId: number;
  render: (user: UserModel) => JSX.Element;
  type?: "superadmin" | "admin";
}

const CognitoUser: React.FC<CognitoUserProps> = ({
  render,
  userId,
  type = "superadmin",
}) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<UserModel | undefined>();

  const getUser = useCallback(async () => {
    try {
      const { data } = await axios.get<UserModel>(`/${type}/users/${userId}`);
      setUser(data);
    } catch (error) {}
    setLoaded(true);
  }, [userId, type]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (!loaded)
    return (
      <div className="p-3 px-6">
        <PulseLoader color="#ff1616" size={10} />
      </div>
    );
  if (!user) return <></>;
  return <>{render(user!)}</>;
};

export default CognitoUser;
