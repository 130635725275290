import { atom } from "recoil";

export interface RegimeOptionType {
  id: string;
  name: string;
  group: string;
}

export const regimeState = atom<RegimeOptionType[]>({
  key: "regimeState",
  default: [],
});
