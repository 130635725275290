import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { toast } from "react-toastify";
import withApiUser from "../../../utils/useUserCompanyRole";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atoms/user";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

type Inputs = {
  name: string;
  location: string;
  stage_inspection: string;
};

const PlotDetailsTab: React.FC<PlotDetailsTabProps> = ({
  plot,
  getPlot,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const authUser = useRecoilValue(userState);

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: plot,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (loading) return;

    setLoading(true);
    try {
      await axios.put(`/admin/sites/${siteId}/plots/${plot.id}`, {
        ...formData,
        users: JSON.stringify(plot.users),
        regime: JSON.stringify(plot.regime),
      });
      toast("Plot details saved!", { type: "success" });
      getPlot();
    } catch (error) {
      toast("Failed to update plot details", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Plot Name:</label>
          <input
            className="text-[#ff1616] font-medium bg-gray rounded-md border-none block w-full"
            {...register("name", { required: true, minLength: 3 })}
            type="text"
            required
            readOnly={authUser?.role === "user"}
          ></input>
        </div>

        <div>
          {authUser?.role !== "user" && (
            <button
              type="submit"
              disabled={loading}
              className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
            >
              {loading ? "Loading..." : "Save Plot details"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default withApiUser(PlotDetailsTab);
