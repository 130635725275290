import Tabs from "../../components/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import ListViewHeader from "../../components/ListViewHeader";
import ManageUsersTab from "./ManageUsersTab";
import { PulseLoader } from "react-spinners";
import { CompanyModel } from "../../types/api";
import axios from "../../plugins/axios";
import withApiUser from "../../utils/useUserCompanyRole";
import UpdateCompanyForm from "../../forms/UpdateCompanyForm";

interface SettingsScreenProps {}

const SettingsScreen: React.FC<SettingsScreenProps> = () => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<CompanyModel | undefined>(undefined);

  const getCompany = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await axios.get<CompanyModel>("admin/company");
      setCompany(data);
    } catch (error) {}
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  if (loading)
    return (
      <div>
        <PulseLoader color="#ff1616" size={10} />
      </div>
    );
  if (!company) return <div>Failed to load company.</div>;

  return (
    <>
      <ListViewHeader title="Account Settings" />
      <div className="px-6">
        <Tabs
          tabs={[
            {
              id: "users",
              title: "Account users",
              component: <ManageUsersTab />,
            },
            {
              id: "company",
              title: "Company Details",
              component: (
                <UpdateCompanyForm company={company} onCompleted={() => {}} />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default withApiUser(SettingsScreen);
