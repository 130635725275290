import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "../plugins/axios";
import { SiteModel } from "../types/api";
import { FormProps } from "../types/app";

interface EditSiteFormProps extends FormProps {
  site: SiteModel;
}
type Inputs = {
  name: string;
  location: string;
  warranty_provider: string;
  registration_date: string;
  inspector: string;
};

const EditSiteForm: React.FC<EditSiteFormProps> = ({ site, onCompleted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: site,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      await axios.put<{
        success: boolean;
        site_id: number;
      }>(`/admin/sites/${site.id}`, {
        ...formData,
      });
      //   navigate(`/sites/${data.site_id}`);
      onCompleted();
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Edit Site Details</h2>
      <div className="my-4">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Site Name *
        </label>
        <input
          id="name"
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Site Name (3 characters min)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Site name is required and must be more than 3 characters
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="location" className="text-sm font-medium mb-2">
          Location
        </label>
        <input
          id="location"
          {...register("location", { required: true, minLength: 1 })}
          placeholder="Enter Location"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.location && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Location is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="warranty_provider" className="text-sm font-medium mb-2">
          Warranty Provider
        </label>
        <input
          id="warranty_provider"
          {...register("warranty_provider", { required: true, minLength: 1 })}
          placeholder="Enter warranty provider"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.warranty_provider && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Warranty Provider is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="registration_date" className="text-sm font-medium mb-2">
          Registration Date
        </label>
        <input
          id="registration_date"
          {...register("registration_date", {
            required: true,
            valueAsDate: true,
          })}
          type="date"
          min="1900-01-01"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.registration_date && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Registration Date is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="inspector" className="text-sm font-medium mb-2">
          Improved inspector or LABC
        </label>
        <input
          id="inspector"
          {...register("inspector", { required: true, minLength: 1 })}
          placeholder="Enter Improved inspector or LABC"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.inspector && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Improved inspector or LABC is required
          </span>
        )}
      </div>

      <button
        type="submit"
        className="focus:outline-none text-white bg-[#ff1616] hover:bg-[#fe5151] focus:ring-4 focus:ring-[#fe5151] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-[#fe5151]"
      >
        Update site
      </button>
    </form>
  );
};

export default EditSiteForm;
