import React, { useEffect, useMemo, useState } from "react";
import useCustomSearchParams from "../../utils/useCustomSearchParams";

export interface Tab {
  id: string;
  title: string;
  component: any;
}
interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [search, setSearch] = useCustomSearchParams();
  const [activeTab, setActiveTab] = useState<string | null>(tabs[0].id);

  useEffect(() => {
    if (search.active_tab && search.active_tab !== activeTab) {
      setActiveTab(search.active_tab);
    }
  }, [search.active_tab, activeTab]);

  const openTab = (id: string) => {
    setSearch({ ...search, active_tab: id });
  };

  const currentTabComponent = useMemo(() => {
    return tabs.find((tb) => tb.id === activeTab)?.component ?? null;
  }, [activeTab, tabs]);

  return (
    <div className="">
      <div className="flex items-end justify-start border-b mb-4 sticky top-0 bg-gray-100">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            role="button"
            onClick={() => openTab(tab.id)}
            className={`p-2 px-4 text-gray-600 font-medium text-sm ${
              tab.id === activeTab ? "border-b-2 border-[#ff1616]" : ""
            }`}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div>{currentTabComponent}</div>
    </div>
  );
};

export default Tabs;
