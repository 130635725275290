import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";

interface ApiImageProps {
  imageUrl: string;
  imageProps: any;
  loadingContainer?: any;
}

const ApiImage: React.FC<ApiImageProps> = ({
  imageUrl,
  imageProps,
  loadingContainer,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [retrievedUrl, setRetrievedUrl] = useState<string>("");

  const getImage = useCallback(async () => {
    try {
      const { data } = await axios.get<string>(`files/${imageUrl}`);
      setRetrievedUrl(data);
      setLoaded(true);
    } catch (error) {}
  }, [imageUrl]);

  useEffect(() => {
    getImage();
  }, [imageUrl, getImage]);

  if (!loaded) return loadingContainer ?? <div />;
  return <img {...imageProps} src={retrievedUrl} alt="the alt" />;
};

export default ApiImage;
