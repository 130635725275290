import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atoms/user";
import { PlotModel } from "../../../types/api";
import withApiUser from "../../../utils/useUserCompanyRole";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

const PlotActionsTab: React.FC<PlotDetailsTabProps> = ({
  plot,
  getPlot,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authUser = useRecoilValue(userState);

  const deletePlot = async () => {
    if (
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to permanently delete this plot, this cannot be undone!"
      )
    ) {
      setLoading(true);

      try {
        await axios.delete(`admin/sites/${siteId}/plots/${plot.id}`);
        toast("Plot has been deleted successfully", {
          type: "success",
        });

        navigate(`/sites/${siteId}`);
      } catch (error) {
        toast("Failed to delete plot, please contact support", {
          type: "error",
        });
      }
      setLoading(false);
    }
  };
  return (
    <div className="max-w-2xl">
      <div className="p-4 rounded-md bg-white mt-6">
        <h3 className="text-xl font-medium">Delete Plot</h3>
        <>
          <p className="text-gray-500 text-sm">
            Remove this plot and all the data within{" "}
            <strong>This cannot be undone!</strong>
          </p>
          {authUser?.role !== "user" ? (
            <div
              role="button"
              onClick={() => {
                deletePlot();
              }}
              className="bg-red-600 text-sm my-2 rounded-md p-3 w-1/3 text-center text-white"
            >
              {loading ? "Loading...." : "Delete"}
            </div>
          ) : (
            <p className="py-3 uppercase text-red-500 font-semibold text-xs">
              Only Admin users can delete plots
            </p>
          )}
        </>
      </div>
    </div>
  );
};

export default withApiUser(PlotActionsTab);
