import React, { useEffect, useState } from "react";
import { ReportSectionType } from "../../../types/app";
import Section from "../parts/ProgressSection";
import { Page } from "@react-pdf/renderer";

interface ProgressPageProps {
  report: any;
}

const ProgressPage: React.FC<ProgressPageProps> = ({ report }) => {
  const [sections, setSections] = useState<ReportSectionType[]>([]);

  useEffect(() => {
    getSectionsCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reports]);
  const getSectionsCount = () => {
    const regime = report.regime;

    var ext = 0,
      int_1 = 0,
      int_2 = 0,
      com = 0,
      roof = 0;
    var ext_comp = 0,
      int_1_comp = 0,
      int_2_comp = 0,
      com_comp = 0,
      roof_comp = 0;

    Object.entries(regime)
      .filter((element) => element[0].startsWith("floor_"))
      .forEach((room: any) => {
        room[1].forEach((roomI: any) => {
          if (roomI.startsWith("external_")) {
            ext++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              ext_comp++;
            }
          } else if (roomI.startsWith("roof_")) {
            roof++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              roof_comp++;
            }
          } else if (roomI.startsWith("internals_1_")) {
            int_1++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_1_comp++;
            }
          } else if (roomI.startsWith("internals_2_")) {
            int_2++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_2_comp++;
            }
          } else if (roomI.startsWith("commissioning_")) {
            com++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              com_comp++;
            }
          }
        });
      });

    const nSections: any = [];

    if (ext > 0) {
      nSections.push({
        id: "external",
        title: "External",
        total: ext,
        completed: ext_comp,
      });
    }

    if (roof > 0) {
      nSections.push({
        id: "roof",
        title: "Roof",
        total: roof,
        completed: roof_comp,
      });
    }

    if (int_1 > 0) {
      nSections.push({
        id: "internals_1",
        title: "Internals 1st fix",
        total: int_1,
        completed: int_1_comp,
      });
    }

    if (int_2 > 0) {
      nSections.push({
        id: "internals_2",
        title: "Internals 2nd fix",
        total: int_2,
        completed: int_2_comp,
      });
    }

    if (com > 0) {
      nSections.push({
        id: "commissioning",
        title: "Commissioning",
        total: com,
        completed: com_comp,
      });
    }

    setSections(nSections);
  };

  return (
    <Page size="A4" style={{ backgroundColor: "#f0f0f0", padding: 40 }}>
      {sections.map((rep) => (
        <Section
          {...rep}
          report={report}
          regime={report.regime}
          reports={report.reports}
          key={rep.id}
        />
      ))}
    </Page>
  );
};

export default ProgressPage;
