import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "../plugins/axios";
import { PlotModel } from "../types/api";
import { FormProps } from "../types/app";

type Inputs = {
  send_to: undefined | "email_addresses" | "trades";
  email?: string;
};

interface ExportPlotFormProps extends FormProps {
  plot: PlotModel;
  siteId: number;
  onCompleted: () => void;
}

const ExportPlotForm: React.FC<ExportPlotFormProps> = ({
  plot,
  onCompleted,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({});

  const sendTo = watch("send_to");

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      await axios.get<any>(`/sites/${siteId}/plots/${plot.id}/export`, {
        params: {
          sendTo: formData.send_to,
          email: formData.email,
        },
      });
      onCompleted();
    } catch (error) {}
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Export Plot</h2>

      <>
        <div className="my-6">
          <label htmlFor="send_to" className="block mb-2 font-medium text-sm">
            Send Export to:
          </label>
          <select
            {...register("send_to")}
            placeholder="-- How would you like to send the export --"
            className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
          >
            <option
              value={undefined}
              disabled
              selected
              className="text-gray-400"
            >
              -- Choose option --
            </option>

            <option value="email_addresses">Email Address</option>
            <option value="assigned_users">Assigned Users</option>
          </select>
        </div>

        {sendTo === "email_addresses" && (
          <div className="my-6">
            <label htmlFor="name" className="text-sm font-medium mb-2">
              Enter email
            </label>
            <input
              {...register("email", { required: true, minLength: 3 })}
              placeholder="Enter email address"
              type="email"
              className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
            {errors.email && (
              <span className="mt-2 text-xs text-red-600 font-medium">
                Email address is required is required and must be more than 3
                characters
              </span>
            )}
          </div>
        )}
        <button
          type="submit"
          className="focus:outline-none text-white bg-[#ff1616] hover:bg-[#fe5151] focus:ring-4 focus:ring-[#fe5151] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-[#fe5151]"
        >
          {loading ? "Loading..." : "Send plot report"}
        </button>
      </>
    </form>
  );
};

export default ExportPlotForm;
