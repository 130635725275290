import React, { useEffect, useMemo, useState } from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { getFloorName, getSectionName } from "../../../utils/stringUtil";
import { ReportSectionType } from "../../../types/app";

interface ReportPageProps {
  report: any;
}

const styles = StyleSheet.create({
  roomAndTypeView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 13,
    marginVertical: 10,
  },
  floorName: {
    color: "#929292",
  },
  sectionName: {
    fontSize: 14,
    color: "#454444",
  },
  contentSection: {
    marginVertical: 3,
  },
  contentSectionName: {
    color: "#929292",
    fontSize: 13,
  },
  contentSectionValue: {
    fontSize: 13,
    color: "#454444",
    marginVertical: 4,
  },
  reportImagesView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginVertical: 2,
  },

  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
  },
  floorTitleViewTotal: {
    borderWidth: 1,
    fontSize: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
  },
});

const imageWidth = 545 / 3 - 10 - 80 / 3;
const ReportPage: React.FC<ReportPageProps> = ({ report }) => {
  const [sections, setSections] = useState<ReportSectionType[]>([]);

  useEffect(() => {
    getSectionsCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reports]);
  const getSectionsCount = () => {
    const regime = report.regime;

    var ext = 0,
      int_1 = 0,
      int_2 = 0,
      com = 0,
      roof = 0;
    var ext_comp = 0,
      int_1_comp = 0,
      int_2_comp = 0,
      com_comp = 0,
      roof_comp = 0;

    Object.entries(regime)
      .filter((element) => element[0].startsWith("floor_"))
      .forEach((room: any) => {
        room[1].forEach((roomI: any) => {
          if (roomI.startsWith("external_")) {
            ext++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              ext_comp++;
            }
          } else if (roomI.startsWith("internals_1_")) {
            int_1++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_1_comp++;
            }
          } else if (roomI.startsWith("internals_2_")) {
            int_2++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_2_comp++;
            }
          } else if (roomI.startsWith("commissioning_")) {
            com++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              com_comp++;
            }
          } else if (roomI.startsWith("roof_")) {
            roof++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              roof_comp++;
            }
          }
        });
      });

    const nSections: any = [];

    if (ext_comp > 0) {
      nSections.push({
        id: "external",
        title: "External",
        total: ext,
        completed: ext_comp,
      });
    }

    if (int_1_comp > 0) {
      nSections.push({
        id: "internals_1",
        title: "Internals 1st fix",
        total: int_1,
        completed: int_1_comp,
      });
    }

    if (int_2_comp > 0) {
      nSections.push({
        id: "internals_2",
        title: "Internals 2nd fix",
        total: int_2,
        completed: int_2_comp,
      });
    }

    if (com_comp > 0) {
      nSections.push({
        id: "commissioning",
        title: "Commissioning",
        total: com,
        completed: com_comp,
      });
    }

    if (roof_comp > 0) {
      nSections.push({
        id: "roof",
        title: "Roof",
        total: roof,
        completed: roof_comp,
      });
    }

    setSections(nSections);
  };

  console.log(report.reports[0]);

  return (
    <Page size="A4" style={{ backgroundColor: "#f0f0f0", padding: 40 }}>
      {sections.map((section) => (
        <>
          <SectionTitle {...section} />

          {report.reports
            .filter((rpt: any) => rpt.type_id.startsWith(`${section.id}_`))
            .map((reportI: any) => (
              <View key={reportI.id} wrap={false}>
                <View style={styles.roomAndTypeView}>
                  <Text style={styles.floorName}>
                    {report?.floors_no === 0 ? 'Single Floor' : getFloorName(reportI.room_or_comm_id)}
                  </Text>
                  <Image
                    src="/pdfassets/arrow.png"
                    style={{
                      width: 10,
                      height: 10,
                      objectFit: "contain",
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  />
                  <Text style={styles.sectionName}>
                    {getSectionName(reportI.type_id)}
                  </Text>
                </View>
                <View style={styles.contentSection}>
                  <Text style={styles.contentSectionName}>Location:</Text>
                  <Text style={styles.contentSectionValue}>
                    {report.site?.location ?? "-"}
                  </Text>
                </View>

                <View style={styles.contentSection}>
                  <Text style={styles.contentSectionName}>Details:</Text>
                  <Text style={styles.contentSectionValue}>
                    {reportI.details}
                  </Text>
                </View>

                <View style={styles.contentSection}>
                  <Text style={styles.contentSectionName}>Images:</Text>
                  <View style={styles.reportImagesView}>
                    {reportI.image_1 && (
                      <Image
                        src={reportI.image_1}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                    {reportI.image_2 && (
                      <Image
                        src={reportI.image_2}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                    {reportI.image_3 && (
                      <Image
                        src={reportI.image_3}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                    {reportI.image_4 && (
                      <Image
                        src={reportI.image_4}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                    {reportI.image_5 && (
                      <Image
                        src={reportI.image_5}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                    {reportI.image_6 && (
                      <Image
                        src={reportI.image_6}
                        style={{
                          width: imageWidth,
                          height: imageWidth,
                          objectFit: "cover",
                          margin: 4,
                        }}
                      />
                    )}
                  </View>
                </View>
              </View>
            ))}
        </>
      ))}
    </Page>
  );
};

export default ReportPage;

const SectionTitle = ({ completed, total, title }: ReportSectionType) => {
  const color = useMemo<string>(() => {
    if (completed === total) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, total]);
  return (
    <View style={styles.sectionHeader} break>
      <View>
        <Text style={{ fontSize: 15 }}>{title}</Text>
      </View>
      <View>
        <Text
          style={[styles.floorTitleViewTotal, { borderColor: color, color }]}
        >
          {completed} of {total} checks completed
        </Text>
      </View>
    </View>
  );
};
