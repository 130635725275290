import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlotModel, SiteModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import FailedToLoadDetails from "../../../components/FailedToLoadDetails";
import { PulseLoader } from "react-spinners";
import { IoArrowBackOutline } from "react-icons/io5";
import CognitoUser from "../../../components/CognitoUser";

interface ManageSiteUsersScreenProps {}

const ManageSiteUsersScreen: React.FC<ManageSiteUsersScreenProps> = () => {
  const navigate = useNavigate();
  let { siteId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useState<SiteModel | null>();
  const [plots, setPlots] = useState<PlotModel[]>([]);
  const [users, setUsers] = useState<
    { id: number; created_at: string; owner: boolean }[]
  >([]);

  const [values, setValues] = useState<{ plotId: number; users: number[] }[]>(
    []
  );

  const getSiteById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<SiteModel>(`/admin/sites/${siteId}`);
      setSite(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [siteId]);

  const getFullPlotsList = useCallback(async () => {
    const { data } = await axios.get<PlotModel[]>(
      `/admin/sites/${siteId}/plots/all`
    );

    setPlots(data);

    setValues(
      data.map((plot) => ({ plotId: plot.id, users: plot.users ?? [] }))
    );
  }, [siteId]);

  const getUsers = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await axios.get<
        { id: number; created_at: string; owner: boolean }[]
      >(`/admin/users`);
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getSiteById();
    getFullPlotsList();
    getUsers();
  }, [getSiteById, getFullPlotsList, getUsers]);

  const checkHasAll = (user: any): boolean => {
    if (user.owner === true) return true;

    var found = values.filter((vv) => vv.users.includes(user.id));

    return values.length === found.length;
  };

  const updateAndRefresh = async () => {
    setLoading(true);

    try {
      const { data } = await axios.patch(`/admin/sites/${siteId}/user-access`, {
        plots: values,
      });

      console.log(data);
    } catch (error) {}

    setLoading(false);
  };

  const onChangeAll = async (v: boolean, user: any) => {
    console.log(v);

    const newValues = [...values].map((valu) => {
      return {
        plotId: valu.plotId,
        users:
          v === true
            ? [...valu.users, user.id]
            : valu.users.filter((us) => us !== user.id),
      };
    });

    setValues(newValues);

    // plotIds.forEach((plotId) => {
    //   var el = document!.getElementById(
    //     `user_${user.id}_plot_${plotId}`
    //   ) as HTMLInputElement;

    //   if (el !== null) {
    //     el.checked = v;
    //     let event = new Event("change");
    //     el.dispatchEvent(event);
    //   }
    // });
    //
  };

  if (error) return <FailedToLoadDetails />;
  if (!site || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        <PulseLoader color="#ff1616" size={10} />
      </div>
    );

  return (
    <div>
      <div className="p-6">
        <div
          role="button"
          className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
          onClick={() => {
            navigate(`/sites/${siteId}`);
          }}
        >
          <IoArrowBackOutline className="mr-2" />
          Back to site
        </div>
        <h1 className="text-[#ff1616] text-xl font-medium my-6">
          Manage {site.name} plot users
        </h1>
      </div>
      <div className="p-6 overflow-auto w-full">
        <table className="table-fixed overflow-scroll w-full">
          <thead>
            <tr>
              <th className=" py-2 text-[#ff1616] text-left w-[200px]">User</th>
              <th className="flex-shrink-0 w-[150px] text-sm text-center">
                All Plots
              </th>
              {plots.map((plot) => (
                <th className="flex-shrink-0 w-[150px] text-sm text-center">
                  {plot.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <CognitoUser
                type="admin"
                userId={user.id}
                render={(userD) => (
                  <tr id={`user-${user.id}`}>
                    <td className="h-16">
                      {userD.name}{" "}
                      <span className="text-xs">({userD.email})</span>
                    </td>
                    <td className="p-2 text-center">
                      <input
                        type="checkbox"
                        checked={checkHasAll(user)}
                        onChange={(e) => onChangeAll(e.target.checked, user)}
                        disabled={user.owner}
                        className="w-4 h-4 text-[#ff1616] bg-white rounded border-[#ff1616] focus:ring-[#ff1616] dark:focus:ring-[#ff1616] dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-[#ff1616] mr-4"
                      />
                    </td>
                    {plots.map((plot) => (
                      <td className="p-2 text-center">
                        <input
                          id={`user_${user.id}_plot_${plot.id}`}
                          type="checkbox"
                          checked={
                            values
                              .find((vv) => vv.plotId === plot.id)
                              ?.users.includes(user.id) || user.owner
                          }
                          onChange={(v) => {
                            if (user.owner) return;

                            const ii = values.findIndex(
                              (ii) => ii.plotId === plot.id
                            );

                            if (v.target.checked) {
                              let evv = [...values];

                              evv[ii] = {
                                plotId: plot.id,
                                users: [...plot.users, user.id],
                              };

                              // evv.push({
                              //   plotId: 1234567,
                              //   users: [],
                              // });
                              setValues(evv);
                            } else {
                              let evv = [...values];

                              evv[ii] = {
                                plotId: plot.id,
                                users: plot.users.filter(
                                  (vv) => vv !== user.id
                                ),
                              };
                              setValues(evv);
                            }
                          }}
                          disabled={user.owner}
                          className="w-4 h-4 text-[#ff1616] bg-white rounded border-[#ff1616] focus:ring-[#ff1616] dark:focus:ring-[#ff1616] dark:ring-offset-gray-800 focus:ring-2 dark:bg-white dark:border-[#ff1616] mr-4"
                        />
                      </td>
                    ))}
                  </tr>
                )}
              />
            ))}
          </tbody>
        </table>

        <button
          className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md mr-2 mt-10"
          onClick={() => {
            updateAndRefresh();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ManageSiteUsersScreen;
