import React, { Fragment, useEffect, useMemo, useState } from "react";
import RegimeConfigInputGroup from "./Group";
// import regime_options from "../../../regime_options.json";
import { getFloorNameByIndex } from "../../../utils/stringUtil";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BlockModel } from "../../../types/api";
import { useRecoilValue } from "recoil";
import { regimeState } from "../../../atoms/regime";
import { getRegimeOptions } from "../../../utils/regime";

interface RegimeConfigInputProps {
  floors: number;
  value?: { [k: string]: any };
  onChange: (v: object) => void;
  isBlock?: boolean;
  communals?: number;
  plots?: number;
  block?: BlockModel;
}

const RegimeConfigInput: React.FC<RegimeConfigInputProps> = ({
  floors,
  value = {},
  onChange,
  isBlock = false,
  communals = 0,
  plots = 0,
  block,
}) => {
  const regimeOptions = useRecoilValue(regimeState);

  const setCurrentValue = (v: { [k: string]: string[] }) => {
    onChange(v);
  };

  useEffect(() => {
    if (regimeOptions.length === 0) {
      getRegimeOptions();
    }
  }, [regimeOptions.length]);

  const groups = useMemo(() => {
    return [
      "external",
      ...(isBlock ? [] : ["roof"]),
      "internals_1",
      "internals_2",
      "commissioning",
    ].map((gr) => ({
      name: gr,
      id: gr,
      items: regimeOptions.filter((ro) => ro.group === gr),
    }));
  }, [isBlock, regimeOptions]);

  const commGroups = useMemo(() => {
    return [
      "external",
      "roof",
      "internals_1",
      "internals_2",
      "commissioning",
    ].map((gr) => ({
      name: gr,
      id: gr,
      items: regimeOptions.filter((ro) => ro.group === gr),
    }));
  }, [regimeOptions]);

  return (
    <div className="my-2">
      {Array.from(Array(isBlock ? 1 : floors), (x, i) => i).map((ari) => (
        <FloorContainer
          no={ari}
          key={ari}
          moreThanOne={floors > 1 && !isBlock}
          applyToAll={(e) => {
            e.preventDefault();
            if (
              // eslint-disable-next-line no-restricted-globals
              confirm(
                "Are you sure? This will overwrite the config for all floors and cannot be undone"
              )
            ) {
              const floor0Val = value[`floor_${ari}`] ?? [];

              const keys = Array.from(Array(floors), (x, i) => i).map(
                (comm) => `floor_${comm}`
              );

              const newVal = { ...value };

              keys.forEach((key) => {
                newVal[key] = floor0Val;
              });

              setCurrentValue(newVal);
            }
          }}
        >
          {groups.map((group) => (
            <RegimeConfigInputGroup
              key={group.id}
              value={value[`floor_${ari}`] ?? []}
              setValue={(v) => {
                setCurrentValue({ ...value, [`floor_${ari}`]: v });
              }}
              items={group.items.map((ii) => ({ id: ii.id, name: ii.name }))}
              name={group.name}
            />
          ))}
        </FloorContainer>
      ))}

      {isBlock && (
        <>
          <label className="text-sm font-medium mb-5 block">Plots</label>
          {Array.from(Array(plots), (x, i) => i).map((comm) => (
            <div className="bg-white p-6 mb-5 rounded-lg shadow-sm flex items-center justify-between">
              <input
                type="text"
                placeholder="Plot Name"
                value={
                  value[`plot_${comm}_name`] ??
                  `${block?.name} - Plot ${comm + 1}`
                }
                onChange={(e) => {
                  setCurrentValue({
                    ...value,
                    [`plot_${comm}_name`]: e.target.value ?? null,
                  });
                }}
                className="font-medium p-0 border-none w-1/2 focus:outline-none focus:ring-0 active:outline-none active:ring-0"
              />
              <label
                className="flex items-center justify-center font-medium"
                htmlFor={`plots_${comm}_has_roof`}
              >
                Roof Applied?{" "}
                <input
                  id={`plots_${comm}_has_roof`}
                  type="checkbox"
                  checked={value[`plot_${comm}_has_roof`] ?? false}
                  onChange={(e) => {
                    setCurrentValue({
                      ...value,
                      [`plot_${comm}_has_roof`]: e.target.checked,
                    });
                  }}
                  className="ml-2 w-4 h-4 text-[#ff1616] bg-gray-100 rounded border-gray-300 focus:ring-[#fe5151] dark:focus:ring-[#fe5151] dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600"
                />
              </label>
            </div>
          ))}
          <label className="text-sm font-medium mb-2">Communals Regime</label>

          <div className="border rounded-md p-4 mb-4 pt-8 mt-2">
            {Array.from(Array(floors), (x, i) => i).map((ari) => (
              <FloorContainer
                no={ari}
                key={ari}
                moreThanOne={floors > 1}
                applyToAll={(e) => {
                  if (
                    // eslint-disable-next-line no-restricted-globals
                    confirm(
                      "Are you sure? This will overwrite the config for all floors"
                    )
                  ) {
                    const floor0Val = value[`communals_floor_0`] ?? [];
                    const keys = Array.from(Array(floors), (x, i) => i).map(
                      (ari2) => `communals_floor_${ari2}`
                    );
                    const newVal = { ...value };

                    keys.forEach((key) => {
                      newVal[key] = floor0Val;
                    });

                    setCurrentValue(newVal);
                  }
                }}
              >
                {commGroups.map((group) => (
                  <RegimeConfigInputGroup
                    key={group.id}
                    value={value[`communals_floor_${ari}`] ?? []}
                    setValue={(v) => {
                      setCurrentValue({
                        ...value,
                        [`communals_floor_${ari}`]: v,
                      });
                    }}
                    items={group.items.map((ii) => ({
                      id: ii.id,
                      name: ii.name,
                    }))}
                    name={group.name}
                  />
                ))}
              </FloorContainer>
            ))}
          </div>
          <label className="text-sm font-medium mb-5 block">Communals</label>

          {Array.from(Array(communals), (x, i) => i).map((comm) => (
            <div className="bg-white p-6 mb-5 rounded-lg shadow-sm flex items-center justify-between">
              <input
                type="text"
                placeholder="Communal Name"
                value={
                  value[`communal_${comm}_name`] ??
                  `${block?.name} - Communal ${comm + 1}`
                }
                onChange={(e) => {
                  setCurrentValue({
                    ...value,
                    [`communal_${comm}_name`]: e.target.value ?? null,
                  });
                }}
                className="font-medium p-0 border-none w-1/2 focus:outline-none focus:ring-0 active:outline-none active:ring-0"
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const FloorContainer = ({
  no,
  children,
  applyToAll,
  moreThanOne,
}: {
  no: number;
  moreThanOne: boolean;
  children: any;
  applyToAll: (e: any) => void;
}) => {
  const [open, setOpen] = useState(no === 0);

  return (
    <div className="bg-white p-6 mb-5 rounded-lg shadow-sm">
      {moreThanOne && (
        <div
          className={`flex items-center justify-between border-b ${
            !open ? "border-white" : "border-gray-100 mb-4 pb-4"
          }`}
        >
          <button
            className="text-gray-500 font-semibold flex items-center justify-start"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            {open ? <FaChevronDown /> : <FaChevronUp />} &nbsp;{" "}
            {getFloorNameByIndex(no)}
          </button>
          {no === 0 && open && (
            <button
              type="button"
              onClick={applyToAll}
              className="border-[#ff1616] rounded-md px-3 py-1 border-2 text-[#ff1616] text-sm"
            >
              Apply to all
            </button>
          )}
        </div>
      )}

      {open && <div className="grid grid-cols-5 gap-4">{children}</div>}
    </div>
  );
};

export default RegimeConfigInput;
