import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Button from "../components/Button";
import RegimeConfigInput from "../components/Inputs/RegimeConfigInput";
import { BlockModel } from "../types/api";
import axios from "../plugins/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface EditBlockRegimeFormProps {
  block: BlockModel;
  siteId: any;
  reloadBlock: () => Promise<void>;
}

type Inputs = {
  regime: { [k: string]: string[] };
};

const EditBlockRegimeForm: React.FC<EditBlockRegimeFormProps> = ({
  block,
  siteId,
  reloadBlock,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    defaultValues: {
      regime: block.regime,
    },
  });

  useEffect(() => {
    reset({
      regime: block.regime,
    });
  }, [block.regime, reset]);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setLoading(true);
    try {
      await axios.put<{
        success: boolean;
      }>(`/admin/sites/${siteId}/blocks/${block.id}`, {
        ...block,
        regime: JSON.stringify(formData.regime),
      });
      toast("Block regime has been saved", {
        type: "success",
      });
      await reloadBlock();
    } catch (error) {}
    setLoading(false);
  };

  const onConfirm = async () => {
    setConfirmLoading(true);
    try {
      await axios.post<{
        success: boolean;
      }>(`/admin/sites/${siteId}/blocks/${block.id}/generate`);
      navigate(`/sites/${siteId}`);
      toast("Plots have been generated for block", {
        type: "success",
      });
    } catch (error) {}
    setConfirmLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-medium mb-4">
          Setup Block Plots & Communals
        </h2>
        {/* <Button title="Save Draft" loading={loading} disabled={loading} /> */}
      </div>

      <div className="my-6">
        <label htmlFor="registration_date" className="text-sm font-medium mb-2">
          Plot Regime
        </label>

        <Controller
          control={control}
          name="regime"
          render={({ field: { onChange, value } }) => (
            <RegimeConfigInput
              floors={block.floors_no}
              communals={block.communals_no}
              isBlock
              value={value}
              onChange={onChange}
              plots={block.plots_no}
              block={block}
            />
          )}
        />
        {errors.regime && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Regime is required
          </span>
        )}
      </div>

      <Button
        title="Save Draft"
        loading={loading}
        disabled={loading}
        color="green"
      />
      <Button
        title="Confirm"
        loading={confirmLoading}
        disabled={confirmLoading || isDirty}
        type="button"
        onClick={onConfirm}
      />
      {isDirty && (
        <div>
          You need to save changes before you can confirm your block regime
        </div>
      )}
    </form>
  );
};

export default EditBlockRegimeForm;
