import { setRecoil } from "recoil-nexus";
import { regimeState } from "../atoms/regime";
import axios from "../plugins/axios";

export const getRegimeOptions = async () => {
  try {
    const { data } = await axios.get("/regime");
    // setRegimeOptions(data);
    setRecoil(regimeState, data.options);
  } catch (error) {}
};
