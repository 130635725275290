import React from "react";
import ListViewHeader from "../../components/ListViewHeader";
import Tabs from "../../components/Tabs";
import { BlockModel } from "../../types/api";

interface ShowInflightBlockScreenProps {
  block: BlockModel;
  siteId: any;
}

const ShowInflightBlockScreen: React.FC<ShowInflightBlockScreenProps> = ({
  block,
  siteId,
}) => {
  return (
    <div>
      <ListViewHeader title={block.name} />

      <div className="px-6">
        <Tabs
          tabs={[
            {
              id: "details",
              title: "Details",
              component: <div />,
            },
            {
              id: "plots",
              title: "Plots",
              component: <div />,
            },
            {
              id: "actions",
              title: "Actions",
              component: <div />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ShowInflightBlockScreen;
