import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import Tabs from "../../../components/Tabs";
import CompanyDetails from "./CompanyDetails";
import CompanyUsers from "./CompanyUsers";
import ActivityLog from "./ActivityLog";
import CompanyShowActionsTab from "./CompanyActions";
import { PulseLoader } from "react-spinners";
import FailedToLoadDetails from "../../../components/FailedToLoadDetails";

const CompaniesShowScreen: React.FC = () => {
  const navigate = useNavigate();
  let { companyId } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CompanyModel | null>();

  const getCompanyById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<CompanyModel>(
        `/superadmin/companies/${companyId}`
      );
      setCompany(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [companyId]);

  useEffect(() => {
    getCompanyById();
  }, [getCompanyById]);

  if (error) return <FailedToLoadDetails />;
  if (!company || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        <PulseLoader color="#ff1616" size={10} />
      </div>
    );
  return (
    <div className="p-6">
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to search
      </div>
      <h1 className="text-[#ff1616] text-xl font-medium my-6">
        {company.name}
      </h1>

      <Tabs
        tabs={[
          {
            id: "details",
            title: "Details",
            component: <CompanyDetails company={company} />,
          },
          {
            id: "users",
            title: "Users",
            component: <CompanyUsers company={company} />,
          },
          {
            id: "log",
            title: "Activity Log",
            component: <ActivityLog companyId={company.id} />,
          },
          {
            id: "actions",
            title: "Actions",
            component: (
              <CompanyShowActionsTab
                company={company}
                reloadCompany={() => getCompanyById()}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CompaniesShowScreen;
