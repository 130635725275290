import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ListViewHeader from "../../components/ListViewHeader";
import { SiteModel, PaginatedType } from "../../types/api";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import axios from "../../plugins/axios";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import Modal from "../../components/Modal";
import CreateSiteForm from "../../forms/CreateSiteForm";
import Table from "../../components/Table";
import { useRecoilValue } from "recoil";
import { userState } from "../../atoms/user";
import withApiUser from "../../utils/useUserCompanyRole";

const SitesListScreen: React.FC = () => {
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<SiteModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const authUser = useRecoilValue(userState);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getSitesList = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<SiteModel[]>>(
        "/admin/sites",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: search.search,
          },
        }
      );
      setSites(data.items);
      setTotal(data.total);
    } catch (error) {
      setError(true);
      setSites([]);
      setTotal(0);
    }
    setLoading(false);
  }, [pagination, search.search]);

  useEffect(() => {
    getSitesList();
  }, [getSitesList, pagination, search.search]);

  return (
    <div>
      <ListViewHeader
        title="Sites List"
        showSearch
        onCreate={
          authUser?.role !== "user" ? () => setShowAdd(true) : undefined
        }
      />

      <Table
        total={total}
        loading={loading}
        error={error}
        rows={sites}
        pagination={pagination}
        headers={[
          "Site Name",
          "Location",
          "Warranty Provider",
          "Registration Date",
          "Approved Inspector/LABC",
          "Created At",
          "Is Active",
          "",
        ]}
        messages={{
          noResults: "No sites found, create one above",
          apiFailed:
            "Failed to load sites list, contact support if this persists.",
        }}
        renderRow={(site: SiteModel) => {
          return (
            <tr
              key={site.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">
                <Link
                  to={`/sites/${site.id}`}
                  className="text-[#ff1616] hover:text-[#fe5151] flex items-center justify-start"
                >
                  {site.name}
                </Link>
              </td>
              <td className="py-2">{site.location ?? "-"}</td>
              <td className="py-2">{site.warranty_provider ?? "-"}</td>
              <td className="py-2">
                {site.registration_date
                  ? new Date(site.registration_date).toLocaleDateString()
                  : "-"}
              </td>
              <td className="py-2">{site.inspector ?? "-"}</td>

              <td className="py-2">
                {new Date(site.created_at).toLocaleDateString()}
              </td>
              <td className="py-2">{site.active ? "Active" : "Expired"}</td>
              <td>
                <Link
                  to={`/sites/${site.id}`}
                  className="text-gray-400 hover:text-[#fe5151]cursor-pointer"
                >
                  <HiOutlineDotsHorizontal size={20} />
                </Link>
              </td>
            </tr>
          );
        }}
      />
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <CreateSiteForm
          onCompleted={async () => {
            await getSitesList();
            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default withApiUser(SitesListScreen);
