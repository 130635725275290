import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IoBusinessOutline,
  IoDocumentOutline,
  IoHomeOutline,
  IoListOutline,
  IoLogOutOutline,
  IoPeopleOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { GoTasklist } from "react-icons/go";

import MenuItem from "./MenuItem";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useRecoilValue } from "recoil";
import { userState } from "../../atoms/user";
import { getApiFileURL } from "../../utils/files";
import { regimeState } from "../../atoms/regime";
import { getRegimeOptions } from "../../utils/regime";

const Sidebar: React.FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const authUser = useRecoilValue(userState);
  const regimeOptions = useRecoilValue(regimeState);
  const isSuperAdmin = useMemo(
    () =>
      user
        ?.getSignInUserSession()
        ?.getIdToken()
        .payload["cognito:groups"].includes("SuperAdmins"),
    [user]
  );

  const getUserInitials = () => {
    const names = user.attributes!.nickname.split(" ");
    try {
      if (names.length > 1) {
        return `${names[0].substring(0, 1).toUpperCase()}${names[1]
          .substring(0, 1)
          .toUpperCase()}`;
      }
    } catch (error) {
      console.log(error);
    }

    return user.attributes!.nickname.substring(0, 2).toUpperCase();
  };

  useEffect(() => {
    if (regimeOptions.length === 0) {
      getRegimeOptions();
    }
  }, [regimeOptions.length]);

  return (
    <div className="bg-white h-screen sticky top-0 flex flex-col items-start justify-between">
      <div className="w-full">
        {authUser?.company.logo ? (
          <CompanyLogo img={authUser!.company.logo} />
        ) : (
          <img src="/logo.png" alt="PlotFire logo" className="w-20 h-20 m-4" />
        )}
        <div className="p-4 w-full ">
          {isSuperAdmin ? (
            <>
              <MenuItem title="Dashboard" path="/" icon={<IoHomeOutline />} />
              <MenuItem
                title="Companies"
                path="/companies"
                icon={<IoBusinessOutline />}
              />
              <MenuItem
                title="Users"
                path="/users"
                icon={<IoPeopleOutline />}
              />
              <hr />
              <MenuItem
                title="Content"
                path="/content"
                icon={<IoDocumentOutline />}
              />
              <MenuItem
                title="Event Log"
                path="/activity-log"
                icon={<IoListOutline />}
              />
            </>
          ) : (
            <>
              <MenuItem title="Dashboard" path="/" icon={<IoHomeOutline />} />
              <MenuItem title="Sites" path="/sites" icon={<GoTasklist />} />

              <hr />

              <MenuItem
                title="Event Log"
                path="/activity-log"
                icon={<IoListOutline />}
              />
              <MenuItem
                title="Settings"
                path="/settings"
                icon={<IoSettingsOutline />}
              />
            </>
          )}
          {/* <MenuItem
            title="Settings"
            path="/settings"
            icon={<IoSettingsOutline />}
          /> */}
        </div>
      </div>
      <div className="w-full">
        <div className="p-4 pl-10 flex items-center">
          <div className="bg-[#ff1616] w-10 h-10 rounded-full text-white flex items-center justify-center">
            {getUserInitials()}
          </div>
          <div className="ml-2">
            <h5 className="text-sm font-medium">{user.attributes!.nickname}</h5>
            <h6 className="text-xs text-gray-400">{user.attributes?.email}</h6>
          </div>
        </div>
        <div
          className="bg-gray-200 w-full p-4 hover:bg-[#ff1616] hover:text-white transition duration-150 ease-in-out flex items-center pl-10"
          role="button"
          onClick={async () => {
            signOut();
          }}
        >
          <IoLogOutOutline className="mr-2" size={27} />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

export const CompanyLogo = ({ img }: { img?: string }) => {
  const [img2, setImage2] = useState("");

  const getFile = useCallback(async () => {
    const val = await getApiFileURL(img);
    setImage2(val);
  }, [img]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  if (!img) return <></>;

  if (!img2) return <></>;

  return <img src={img2} alt="Logo" className="w-20 h-20 m-4 object-contain" />;
};
