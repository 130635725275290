import React, { useState } from "react";
import Button from "../../../components/Button";
import ListViewHeader from "../../../components/ListViewHeader";
import Modal from "../../../components/Modal";
import Tabs from "../../../components/Tabs";
import ExportPlotForm from "../../../forms/ExportPlotForm";
import { PlotModel } from "../../../types/api";
import PlotActionsTab from "./Actions";
import PlotDetailsTab from "./DetailsTab";
import PlotProgressTab from "./Progress";
import PlotReportsTab from "./Reports";
import PlotUsersTab from "./Users";
import axios from "../../../plugins/axios";

interface ShowInflightPlotScreenProps {
  plot: PlotModel;
  siteId: any;
  getPlotById: () => Promise<void>;
}

const ShowInflightPlotScreen: React.FC<ShowInflightPlotScreenProps> = ({
  plot,
  siteId,
  getPlotById,
}) => {
  const [showExport, setShowExport] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const getReportToken = async () => {
    setReportLoading(true);

    try {
      const { data } = await axios.post(`exports/${plot.id}`);
      window.open(`/plot-report/${data.token}`, "_blank");
    } catch (error) {
      console.error(error);
    }

    setReportLoading(false);
  };

  const duplicatePlot = async () => {
    setReportLoading(true);

    try {
      const { data } = await axios.post(
        `admin/sites/${siteId}/plots/${plot.id}/duplicate`
      );

      alert(
        "Plot has been duplicated, you will now be redirected to newly created plot."
      );

      // @ts-ignore
      window.location = `/sites/${siteId}/plots/${data.plot_id}`;
    } catch (error) {
      console.error(error);
    }
    setReportLoading(false);
  };

  return (
    <div>
      <div className="flex items-start justify-between">
        <ListViewHeader title={plot.name} actions={<> </>} />
        <div className="w-[360px] flex items-center justify-end pr-6 pt-5">
          <Button
            type="button"
            onClick={async () => {
              window.location.reload();
            }}
            title="Reload"
            color="green"
          />
          <Button
            type="button"
            onClick={async () => {
              duplicatePlot();
            }}
            title="Duplicate"
            color="gray"
          />
          <Button
            type="button"
            onClick={getReportToken}
            title="Report"
            loading={reportLoading}
          />
        </div>
      </div>

      <div className="px-6">
        <Tabs
          tabs={[
            {
              id: "details",
              title: "Details",
              component: (
                <PlotDetailsTab
                  plot={plot}
                  getPlot={getPlotById}
                  siteId={siteId}
                />
              ),
            },
            {
              id: "users",
              title: "Users",
              component: (
                <PlotUsersTab
                  plot={plot}
                  getPlot={getPlotById}
                  siteId={siteId}
                />
              ),
            },
            {
              id: "progress",
              title: "Progress",
              component: (
                <PlotProgressTab
                  plot={plot}
                  getPlot={getPlotById}
                  siteId={siteId}
                />
              ),
            },
            {
              id: "reports",
              title: "Reports",
              component: (
                <PlotReportsTab
                  plot={plot}
                  getPlot={getPlotById}
                  siteId={siteId}
                />
              ),
            },
            {
              id: "actions",
              title: "Actions",
              component: (
                <PlotActionsTab
                  plot={plot}
                  getPlot={getPlotById}
                  siteId={siteId}
                />
              ),
            },
          ]}
        />
      </div>

      <Modal shown={showExport} onClose={() => setShowExport(false)}>
        <ExportPlotForm
          plot={plot}
          onCompleted={() => setShowExport(false)}
          siteId={siteId}
        />
      </Modal>
    </div>
  );
};

export default ShowInflightPlotScreen;
