import React, { useMemo  } from "react";
import { ReportModel } from "../../../types/api";
import { ReportSectionType } from "../../../types/app";
import { getFloorName, getSectionName } from "../../../utils/stringUtil";
import {  Text, View, StyleSheet } from "@react-pdf/renderer";
import { BsFillCheckSquareFill } from "react-icons/bs";

const styles = StyleSheet.create({
  sectionView: {
    backgroundColor: "white",
    marginBottom: 20,
    borderRadius: 5,
    overflow: "hidden",
    fontSize: 14,
  },
  sectionTitleView: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    color: "white",
    flexDirection: "row",
    padding: 10,
  },
  sectionViewContent: {
    padding: 20,
  },
  floorView: {
    marginBottom: 15,
    marginTop: 15,
  },
  floorTitleView: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    color: "black",
    flexDirection: "row",
    fontSize: 14,
    fontWeight: 600,
  },
  floorTitleViewTotal: {
    borderWidth: 1,
    fontSize: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
  },
  floorContentView: {
    borderLeftWidth: 1,
    paddingLeft: 10,
    fontSize: 11,
    color: "gray",
    borderColor: "lightgray",
  },
  floorContentViewItem: {
    borderBottomWidth: 1,
    paddingTop: 10,
    paddingBottom: 10,
    borderColor: "lightgray",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

interface SectionCompProps extends ReportSectionType {
  report: any;
  reports: ReportModel[];
  regime: { [k: string]: string[] };
}
const Section: React.FC<SectionCompProps> = ({
  id,
  title,
  total,
  completed,
  regime,
  reports,
  report
}) => {
  const color = useMemo<string>(() => {
    if (completed === total) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, total]);

  const floors = useMemo<any>(() => {
    let items: any[] = [];

    Object.entries(regime).forEach((floor) => {
      floor[1].forEach((opt) => {
        if (opt.startsWith(`${id}_`)) {
          const reportI = reports.filter(
            (rpt) => rpt.type_id === opt && rpt.room_or_comm_id === floor[0]
          );

          items.push({
            id: `${floor[0]}_${opt}`,
            title: opt,
            group: floor[0],
            complete: reportI.length > 0 ? reportI[0].completed : false,
            report: reportI.length > 0 ? reportI[0] : null,
          });
        }
      });
    });

    return items
      .sort((a, b) => a.group.localeCompare(b.group))
      .reduce((groups, floor) => {
        const { group } = floor;
        groups[group] = groups[group] ?? [];
        groups[group].push(floor);
        return groups;
      }, {});
  }, [id, regime, reports]);

  return (
    <View style={styles.sectionView}>
      <View style={[styles.sectionTitleView, { backgroundColor: color }]}>
        <Text>{title}</Text>
        <Text>
          {completed}/{total}
        </Text>
      </View>
      <View style={styles.sectionViewContent}>
        {Object.entries(floors).map((floor) => (
          <Floor id={floor[0]} items={floor[1]} report={report} />
        ))}
      </View>
    </View>
  );
};

const Floor: React.FC<any> = ({ id, items, report }) => {
  const completed = useMemo(() => {
    return items.filter((itm: any) => itm.complete).length;
  }, [items]);

  const color = useMemo<string>(() => {
    if (completed === items.length) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, items.length]);

  return (
    <View style={styles.floorView} wrap={false}>
      <View style={styles.floorTitleView}>
        <Text>{report.floors_no === 0 ? 'Single Floor' : getFloorName(id)}</Text>
        <View
          style={[styles.floorTitleViewTotal, { borderColor: color, color }]}
        >
          <Text>
            {completed} of {items.length} checks completed
          </Text>
        </View>
      </View>
      <View style={styles.floorContentView}>
        {items.map((itm: any) => (
          <View style={styles.floorContentViewItem}>
            <Text>{getSectionName(itm.title)}</Text>
            {/* <input type="checkbox" checked={itm.complete} /> */}

            {itm.complete ? (
              <View
                style={{
                  backgroundColor: "green",
                  height: 16,
                  width: 16,
                  borderRadius: 4,
                  color: "white",
                  fontSize: 20,
                }}
              >
                <Text>
                  <BsFillCheckSquareFill />
                </Text>
              </View>
            ) : (
              <View
                style={{
                  borderWidth: 1.2,
                  borderColor: "red",
                  height: 16,
                  width: 16,
                  borderRadius: 4,
                }}
              ></View>
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

export default Section;
