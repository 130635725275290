/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlockModel } from "../../types/api";
import axios from "../../plugins/axios";
import EditBlockRegimeForm from "../../forms/EditBlockRegimeForm";
import ShowInflightBlockScreen from "./show";
import { IoArrowBackOutline } from "react-icons/io5";

const EditBlockScreen: React.FC = () => {
  const navigate = useNavigate();

  let { siteId, blockId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState<BlockModel | null>();

  const getBlockById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<BlockModel>(
        `/admin/sites/${siteId}/blocks/${blockId}`
      );
      setBlock(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [blockId, siteId]);

  useEffect(() => {
    getBlockById();
  }, [getBlockById]);

  if (!block) return null;

  return (
    <div>
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black p-6"
        onClick={() => {
          navigate(`/sites/${siteId}`);
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to plots list
      </div>
      <div className="w-[98%] mx-auto">
        {block.plots.length > 0 ? (
          <ShowInflightBlockScreen block={block!} siteId={siteId} />
        ) : (
          <EditBlockRegimeForm
            block={block!}
            siteId={siteId}
            reloadBlock={async () => {
              await getBlockById();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditBlockScreen;
