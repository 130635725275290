import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "../plugins/axios";
import { FormProps } from "../types/app";

type Inputs = {
  name: string;
  enterprise_company: boolean;
};

const CreateCompanyForm: React.FC<FormProps> = ({ onCompleted }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      enterprise_company: false,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      const { data } = await axios.post<{
        success: boolean;
        company_id: number;
      }>("/superadmin/companies", {
        name: formData.name,
        enterprise: formData.enterprise_company,
      });
      navigate(`/companies/${data.company_id}`);
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Create new company</h2>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Company Name
        </label>
        <input
          id="name"
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Company Name (3 characters min)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Company name is required and must be more than 3 characters
          </span>
        )}
      </div>
      <div className="my-6">
        <label className="inline-flex items-center">
          <input
            {...register("enterprise_company")}
            type="checkbox"
            className="rounded bg-white border-transparent focus:border-transparent focus:bg-gray-200 text-gray-700 ring-1 ring-gray-300 focus:ring-offset-2 focus:ring-gray-500"
          />{" "}
          <span className="ml-2">Is Enterprise</span>
        </label>
      </div>
      <button
        type="submit"
        className="focus:outline-none text-white bg-[#ff1616] hover:bg-[#fe5151] focus:ring-4 focus:ring-[#fe5151] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-[#fe5151]"
      >
        Create company
      </button>
    </form>
  );
};

export default CreateCompanyForm;
