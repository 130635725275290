import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error404Screen from "./screens/errors/e404";
import Dashboard from "./screens/dashboard";
import SettingsScreen from "./screens/settings";
import UnregisteredUserScreen from "./screens/errors/user-not-registered";
import { RecoilRoot } from "recoil";
import CompaniesListScreen from "./screens/companies";
import CompaniesShowScreen from "./screens/companies/show";
import UsersList from "./screens/users";
import SitesListScreen from "./screens/sites";
import SitesShowScreen from "./screens/sites/show";
import EditPlotScreen from "./screens/plots/edit";
import EditBlockScreen from "./screens/blocks/edit";
import CompanyEventLogPage from "./screens/event-log/company";
import PlotReportScreen from "./screens/plot-report";
import { useMatchedRoute } from "./utils/useCurrentPath";
import EventLogPage from "./screens/event-log";
import ManageSiteUsersScreen from "./screens/sites/show/manage-users";
import RecoilNexus from "recoil-nexus";

function App() {
  const plotReportRoutes = useMatchedRoute([{ path: "/plot-report/:plotId" }]);

  return (
    <RecoilRoot>
      <RecoilNexus />
      {plotReportRoutes ? (
        <Routes>
          <Route path="plot-report/:plotId" element={<PlotReportScreen />} />
        </Routes>
      ) : (
        <>
          <Authenticator
            hideSignUp={process.env.NODE_ENV !== "development"}
            signUpAttributes={["name", "nickname"]}
            className="flex items-center justify-center w-screen h-screen"
          >
            {({ user }) => {
              const isSuperAdmin = (
                user?.getSignInUserSession()?.getIdToken()?.payload[
                  "cognito:groups"
                ] ?? []
              ).includes("SuperAdmins");
              return (
                <div className="grid grid-cols-12 bg-gray-100 min-h-screen ">
                  <div className="col-span-2">
                    <Sidebar />
                  </div>
                  <div className="col-span-10">
                    <Routes>
                      <Route
                        path="/"
                        element={<Dashboard isSuperAdmin={isSuperAdmin} />}
                      />
                      {isSuperAdmin ? (
                        <>
                          <Route
                            path="/companies"
                            element={<CompaniesListScreen />}
                          />
                          <Route
                            path="/companies/:companyId"
                            element={<CompaniesShowScreen />}
                          />
                          <Route path="/users" element={<UsersList />} />

                          <Route
                            path="/activity-log"
                            element={<EventLogPage />}
                          />
                        </>
                      ) : (
                        <>
                          <Route
                            path="/activity-log"
                            element={<CompanyEventLogPage />}
                          />
                          <Route path="/sites" element={<SitesListScreen />} />
                          <Route
                            path="/sites/:siteId"
                            element={<SitesShowScreen />}
                          />
                          <Route
                            path="/sites/:siteId/manage-users"
                            element={<ManageSiteUsersScreen />}
                          />
                          <Route
                            path="/sites/:siteId/plots/:plotId"
                            element={<EditPlotScreen />}
                          />
                          <Route
                            path="/sites/:siteId/blocks/:blockId"
                            element={<EditBlockScreen />}
                          />
                          <Route
                            path="/settings"
                            element={<SettingsScreen />}
                          />
                        </>
                      )}
                      {/* <Route
                      path="plot-report/:plotId"
                      element={<PlotReportScreen />}
                    /> */}

                      <Route
                        path="user-not-registered"
                        element={<UnregisteredUserScreen />}
                      />
                      <Route path="*" element={<Error404Screen />} />
                    </Routes>
                  </div>
                </div>
              );
            }}
          </Authenticator>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        </>
      )}
    </RecoilRoot>
  );
}

export default App;
