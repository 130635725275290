import React from "react";

interface DashboardProps {
  isSuperAdmin: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isSuperAdmin }) => {
  if (isSuperAdmin)
    return <div className="p-3">No super admin dashboard has been setup</div>;
  return (
    <div className="p-10">
      <h1 className="font-bold text-xl text-[#ff1616]">Dashboard</h1>
      {/* <CompanyOverviewComponent /> */}
    </div>
  );
};

export default Dashboard;
