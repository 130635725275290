import { matchPath, useLocation } from "react-router";

export function useMatchedRoute(routes: any[] = []) {
  const { pathname } = useLocation();
  for (const route of routes) {
    if (matchPath({ path: route.path }, pathname)) {
      return route;
    }
  }
}
