import React from "react";
import { useNavigate } from "react-router-dom";

const Error404Screen: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen fixed left-0 top-0 bg-white flex items-center justify-center flex-col">
      {/* <img src="/images/house-wind.png" alt="404 page" /> */}
      <h1 className="text-8xl font-medium text-[#ff1616]">404</h1>
      <h3 className="text-xl text-gray-500 my-10 max-w-md text-center">
        The page you are looking for could not be found, click the button bellow
        to return to your dashboard.
      </h3>
      <button
        onClick={() => navigate("/")}
        className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
      >
        Back to dashboard
      </button>
    </div>
  );
};

export default Error404Screen;
